.new-request {
  padding: 20px;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
}

.file-upload-label {
  background-color: #ffeb3b;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  width: 220px;
  text-align: center;
}

.file-upload-label input {
  display: none;
}

.file-upload button:hover {
  background-color: #303f9f;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.file-table th {
  background-color: #f2f2f2;
}

.file-table td {
  text-align: center;
}

.add-request-button {
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 220px;
  text-align: center;
}

.add-request-button:hover {
  background-color: #e64a19;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-req-container {
  display: flex;
  width: 100%;
  justify-content: right;
}

.file-upload-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  color: Black;
  margin-right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.close-modal:hover {
  background-color: white;
}
