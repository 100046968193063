.wrapper {
  margin: 0 auto;
}

.content-area {
  background-color: #f9fafb;
  padding: 20px 0;
  border-radius: 8px;

}

.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.team-overview {
  margin-top: 20px;
}

.team-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
}

.team-member span {
  flex: 1;
  text-align: left;
}

.btn {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.btn:hover {
  background-color: #3730a3;
}

.btn-close {
  background-color: #e5e7eb;
  color: #374151;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: #d1d5db;
}

.btn-gred {
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.modal-backdrop{
  display: none;
}