/* General container for the privacy policy page */
.privacy-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  text-align: start;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  color: #00539f;
  margin-top: 30px;
}

p {
  font-size: 16px;
  color: #555;
  margin: 10px 0;
}

a {
  color: #00539f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}


h1,
h2,
h3 {
  margin-top: 40px;
}

h1 + p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 15px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 14px;
  }

  th,
  td {
    font-size: 14px;
  }
}
