.settings-page {
  padding: 20px;

  background-color: #f9f9f9;
}

.settings-page h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.settings-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  font-size: 16px;
}

.settings-item a {
  color: #5a67d8;
  text-decoration: none;
}

.settings-item a:hover {
  text-decoration: underline;
}

.company-logo {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.Modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: auto !important;
  max-width: 80%;
  margin: auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-modal {
  background: transparent;
  border: none;
  color: gray;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}

.settings-nav {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.settings-nav button {
  padding: 0.5rem 1rem;
  background: none;
  color: black;
  border-radius: 5px solid black;
  cursor: pointer;
}

.settings-nav .active {
  font-weight: bold;
  background: black;
  color: white;
}

.tab-content {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
}

/* Container for the subcategories list */
.subcategories-container {
  margin: 0;

  border-radius: 8px;
  background-color: transparent;
}
.subcategories-container h3 {
  font-size: 16px !important;
  margin-top: 0 !important;
  font-family: SF-Pro-Display-Semibold !important;
}

/* Each subcategory item */
.subcategory-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
}

.subcategory-item:last-child {
  border-bottom: none;
}

/* Subcategory name */
.subcategory-name {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-right: 20px;
}

/* Risk level selection */
.risk-levels {
  display: flex;
  gap: 15px;
}

/* Individual radio buttons */
.risk-level-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.risk-level-label input {
  margin-right: 8px;
}

/* Risk level colors */
.risk-low {
  color: #3ba55d;
}

.risk-medium {
  color: #e8b923;
}

.risk-high {
  color: #f44336;
}

.risk-level-label:hover {
  background-color: #f1f1f1;
}

.risk-level-label input[type='radio']:checked + .risk-low {
  background-color: #eafaf0;
  color: #3ba55d;
}

.risk-level-label input[type='radio']:checked + .risk-medium {
  background-color: #fff6e0;
  color: #e8b923;
}

.risk-level-label input[type='radio']:checked + .risk-high {
  background-color: #fdecea;
  color: #f44336;
}
/* Dropdown container */
.dropdown-container {
  position: relative;
  width: 300px;
  margin: 20px 0;
}

/* Dropdown select */
.dropdown-select {
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
  appearance: none; /* Remove default dropdown arrow */
}

/* Dropdown arrow */
.dropdown-container::after {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #999;
  pointer-events: none;
}

/* Hover and focus styles */
.dropdown-select:hover,
.dropdown-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Option list (optional, if using custom-styled dropdowns) */
.dropdown-option {
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.dropdown-option:hover {
  background-color: #f1f1f1;
}

/* Toggle switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 10px;
}

/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider styling */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked styling */
input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Risk dropdown styling */
.risk-dropdown {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

/* Specific styles for different risk levels */
.risk-dropdown.low {
  background-color: #d4edda; /* Light green */
  color: #155724;
}

.risk-dropdown.low_flexibility {
  background-color: #fef3c7; /* Light yellow */
  color: #856404;
}

.risk-dropdown.medium {
  background-color: #fff3cd; /* Light orange */
  color: #856404;
}

.risk-dropdown.high {
  background-color: #f8d7da; /* Light red */
  color: #721c24;
}

.risk-dropdown.off {
  background-color: #e2e3e5; /* Light grey */
  color: #6c757d;
}

/* Email input styling */
.email-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.email-input.input-error {
  border-color: #e3342f; /* Red border on error */
}

/* Error text */
.error-text {
  color: #e3342f;
  font-size: 12px;
  margin-top: 2px;
  display: block;
}

.custom-toggle {
  width: 60px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  cursor: pointer;
  background-color: #c2c2c2;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.custom-toggle.active {
  background-color: #6a5acd; /* Adjust the color as needed */
}

.custom-toggle::before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.custom-toggle.active::before {
  left: 24px; /* Move to the right when active */
}

.pause-input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s;
}

.pause-input:focus {
  border-color: #6a5acd; /* Adjust color as needed */
  box-shadow: 0 0 5px rgba(106, 90, 205, 0.3);
}

/* General settings */
.settings-page {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Tabs styling */
.settings-nav button {
  padding: 10px 20px;
  margin-right: 5px;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: #f9f9f9;
  cursor: pointer;
  font-weight: bold;
  color: #555;
}

/* Active Alerts Heading */
.alerts-section h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  height: 60px;
  padding: 10px 15px;
  background-color: #f7f8fa;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Alerts section */
.alerts-section {
  margin-top: 20px;
}

.standard-alerts h3,
.custom-alerts h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Alert Item Container */
.alert-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f7f8fa;
  border-radius: 8px;

  margin-bottom: 10px;
}

/* Alert Title */
.alert-name {
  font-size: 14px !important;
  font-weight: bold;
  color: #000 !important;
  margin-top: 10px !important;
}

/* Alert Description */
.alert-description {
  font-size: 14px;
  color: #6b7280;
  margin-right: auto;
}

/* Alert Controls */
.alert-controls {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #6b7280;
}

.alert-controls span {
  margin-right: 7px;
}

.alert-toggle {
  background-color: #d3d3d3;
  border: none;
  padding: 5px 15px;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.alert-toggle.active {
  background-color: #4a90e2;
}

.alert-toggle:hover {
  opacity: 0.8;
}

/* Custom alert form */
.custom-alerts .alert-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-alerts input[type='text'],
.custom-alerts select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.custom-alerts input[type='text']:focus,
.custom-alerts select:focus {
  border-color: #4a90e2;
}

.create-alert-btn {
  background-color: #4a90e2;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.create-alert-btn:hover {
  background-color: #357ab8;
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.4s;
}

/* Checked state */
input:checked + .slider {
  background-color: #4a90e2;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Hover effect */
.switch:hover .slider {
  background-color: #a1c4f4;
}

.download-report-btn {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-report-btn:hover {
  background-color: #0056b3;
}

.download-report-btn svg {
  stroke: white; /* Ensure the icon matches the button text color */
}
.styled-dropdown {
  width: 200px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.styled-dropdown:focus {
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.5);
}

.date-picker-container {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.download-report-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-report-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.download-report-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-top: 10px;
}

/* Filters Section Container */
.filters-menu {
  padding: 20px;
  background-color: #f7f8fa;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Heading for Filters Section */
.filters-menu h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #d2d4d6;
  padding-bottom: 5px;
}

/* Description for Filters */
.filters-menu p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

/* Dropdown styling */
.filters-menu .dropdown-select {
  width: 100%;
  max-width: 300px;
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Dropdown focus and hover */
.filters-menu .dropdown-select:hover,
.filters-menu .dropdown-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.filters-menu div > div:hover {
  border-color: #007bff;
}

/* Save Button */
.filters-menu button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.filters-menu button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.filters-menu button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Add subtle animation to dropdown appearance */
.filters-menu .dropdown-select {
  animation: fadeIn 0.3s ease;
}

/* Animation for dropdown */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Alignment for color picker and text */
.filters-menu .color-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filters-menu .color-picker-wrapper h5 {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}
