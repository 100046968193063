@font-face {
  font-family: SF-Pro-Display-Black;
  src: url(../fonts/SF-Pro-Display-Black.otf);
}

@font-face {
  font-family: SF-Pro-Display-Regular;
  src: url(../fonts/SF-Pro-Display-Regular.otf);
}

@font-face {
  font-family: SF-Pro-Display-Bold;
  src: url(../fonts/SF-Pro-Display-Bold.otf);
}

@font-face {
  font-family: SF-Pro-Display-Light;
  src: url(../fonts/SF-Pro-Display-Light.otf);
}

@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url(../fonts/SF-Pro-Display-Semibold.otf);
}

@font-face {
  font-family: SF-Pro-Display-Medium;
  src: url(../fonts/SF-Pro-Display-Medium.otf);
}

* {
  box-sizing: border-box;
  font-family: SF-Pro-Display-Regular;
}

/* styles.css */
/* body.light-theme {
  --bg-color: #ffffff;
  --text-color: #000000;
} */

body.dark-theme {
  background-color: #121212 !important;

  .login-heading {
    color: #ffffff !important;
  }

  .auth-container {
    border: none !important;
    background-color: #212121 !important;
  }

  .modal_close_btn svg {
    stroke: #fff !important;
  }

  .mode_theme_btn {
    background-color: #212121 !important;
    font-size: 10px !important;
    border-radius: 100px;
    padding: 0;
    border: none !important;
    color: #ffffff;
    text-transform: uppercase !important;
    font-family: SF-Pro-Display-Semibold;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo_wrps {
    width: 200px;
    height: 74px;
    background-image: url(../img/newlogo.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: auto;
  }

  .form-group label {
    color: #777777 !important;
  }

  .form-group input {
    background-color: #121212 !important;
    border: none !important;
    color: #fff !important;
    outline: none !important;
  }

  .check_trm {
    color: #777777 !important;
  }

  .check_trm a {
    color: #fff !important;
  }

  .button-signup span,
  .button-signup span b {
    color: #fff !important;
  }

  .button-signup span b:hover {
    border-bottom: none !important;
  }

  .signup-text {
    color: #fff !important;
  }

  .auth-btn {
    background-color: #0a0a0a !important;

    &:hover {
      background-color: #121212 !important;
    }
  }

  .wrapper,
  .content-area {
    background-color: #121212 !important;
  }

  .sidenav.user {
    background-color: #121212 !important;
    border-right: none !important;
  }

  .btn_upld_head {
    background-color: #212121 !important;
    border: 1px solid #212121 !important;
    color: #fff !important;
  }

  .btn_upld_head:hover svg {
    stroke: #ffffff !important;
  }

  .btn_uplo_doc:hover {
    background-color: #323232 !important;
  }

  .cirnotisvg {
    background-color: #212121 !important;
  }

  .notification-bell div svg {
    stroke: #fff !important;
  }

  .title,
  .dashcards h4,
  .chart-title {
    color: #fff !important;
  }

  .cards {
    background-color: #171717 !important;
    border: 1px solid #171717 !important;
  }

  .dashcards p {
    color: #777777 !important;
  }

  .apexcharts-legend-text {
    color: #fff !important;
    margin-left: 3px !important;
    padding-left: 2px !important;
  }

  .sidenav.user .logo-class {
    border-bottom: none !important;
  }

  .search-header input {
    background-color: #212121 !important;
    border: 1px solid #212121 !important;
    outline: none !important;
    color: #fff !important;
  }

  .search-header input::placeholder {
    color: #777777 !important;
  }

  .search-header svg {
    stroke: #777777 !important;
  }

  .sspn,
  .sspn1 {
    background-color: #161616 !important;
  }

  .sspn1 {
    color: #777777 !important;
  }

  .newsearchlist {
    background-color: #212121 !important;
  }

  .newsearbtn {
    background-color: #121212 !important;
  }

  .newsearchlist p {
    color: #d5d5d5 !important;
  }

  .smartpromptbtn li {
    color: #777777 !important;
  }

  .wrapnewbtns {
    border-bottom: 1px solid #464646 !important;
  }

  .sidenav.user button {
    color: #8c8c8c !important;
  }

  .sidenav.user button:hover,
  .sidenav.user button.active {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .company-info {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .company-name,
  .cards_headings {
    color: #fff !important;
  }

  .task_tabl tr th {
    background-color: #212121 !important;
    color: #777777 !important;
  }

  td {
    background-color: #171717 !important;
    color: #fff !important;
  }

  .task_tabl tr td,
  .task_tabl tr th {
    border-bottom: 1px solid #2f2f2f ;
  }

  .btn_td_tabs {
    color: #777777 !important;
    border: 1px solid #777777 !important;
    background-color: transparent !important;
  }

  .actions_ulss {
    background-color: #121212 !important;
  }
  .paspro {
    color: #fff !important;
  }
  .wrap_offcanv {
    background-color: #212121 !important;
  }

  .btn_td_tabs:hover {
    background-color: transparent !important;
  }

  .btntds.wid_40pxls .dropdown-toggle {
    width: auto !important;
    padding: 2px 5px !important;
    background-color: #121212 !important;
    border: 1px solid #121212 !important;
  }

  .drp_btn_tds svg {
    stroke: #777777 !important;
  }

  .disc_tab_btn:hover,
  .active1 {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .disc_tab_btn.active1 {
    color: #fff !important;
  }

  .activelistlbl {
    background-color: #121212 !important;
  }

  .activelistlbl svg:nth-child(1) {
    color: #777777 !important;
  }

  .disc_tab_btn::before {
    background-color: #8c8c8c !important;
  }

  .active1.disc_tab_btn::before {
    background-color: #fff !important;
  }

  .disc_tab_btn {
    color: #8c8c8c !important;
  }

  .btntds.wid_40pxls .dropdown-menu {
    background-color: #121212 !important;
  }

  .actions_ulss li button {
    color: #fff !important;
  }

  .actions_ulss li button svg {
    stroke: #fff !important;
  }

  .actions_ulss li button:hover {
    background-color: #1e1e1e !important;
  }

  .redlineselct select {
    background-color: #212121 !important;
    border: 1ps solid #212121 !important;
  }

  .readline-btn {
    background-color: #212121 !important;
    border: none !important;
  }

  .doc_nav_tbas_buttons button.active {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .notes-section {
    background-color: #171717 !important;
  }

  .go-back2 {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .go-back2 svg {
    stroke: #fff !important;
  }

  .notes-section textarea {
    background-color: #212121 !important;
  }

  .notes-section h3 {
    color: #fff !important;
  }

  .notes-section button {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .font_heading_doc {
    color: #fff !important;
  }

  .report_selects select {
    background-color: #212121 !important;
    border: none !important;
    color: #fff !important;
  }

  .report_selects svg {
    stroke: #fff !important;
  }

  .slider2 {
    background-color: #212121 !important;
  }

  .slider2:before {
    background-color: #121212 !important;
    color: #999696 !important;
  }

  .full-view,
  .pre-view {
    color: #999696 !important;
  }

  .switch2 input:checked + .slider2 {
    border: 1px solid #171717 !important;
  }

  .button-send {
    background-color: #212121 !important;
    color: #ffffff !important;
  }

  .settings-page {
    background-color: #121212 !important;
  }

  .settings-nav .active {
    background-color: #121212 !important;
    color: #fff !important;
  }

  .settings-nav button {
    color: #8c8c8c !important;
  }

  .cards_headings22,
  .settings-item span {
    color: #fff !important;
  }

  .settings-item div button {
    color: #8c8c8c;
  }

  .checkbox_chss_fee:checked + .switch_chss_fee {
    background-color: rgb(91, 5, 11);
  }

  .edit-button {
    background-color: #282828 !important;
  }

  .dropdown-select {
    background-color: #282828 !important;
    color: #fff !important;
    border: none !important;
  }

  .settings-nav + .tab-content > .profile-settings .wrapper {
    background-color: #171717 !important;
  }

  .settings-nav + .tab-content > .profile-settings .wrapper .content-area {
    background-color: #171717 !important;
  }

  .btn-gred {
    background-color: #2b2b2b !important;
  }

  .teamtable tr th ,  .teamtable tr td {
    background-color: #212121 !important ;
    color: #777777 !important;
    border-bottom: 1px solid rgb(55, 55, 55);
  }

  .teamtable tr td {
    background-color: #171717 !important;
  }

  .inititab div p {
    color: #fff !important;
  }

  .roletags {
    color: rgb(193, 255, 244) !important;
    border: 1px solid rgb(193, 255, 244);
  }

  .inititab span {
    color: #000 !important;
  }

  .alert-item {
    background-color: #212121;
  }

  .alert-name {
    color: #fff !important;
  }

  .alert-description {
    color: #8c8c8c !important;
  }

  .discover_headings {
    color: #fff !important;
  }

  .cre_requ_btn {
    background-color: #212121 !important;
    border: 1px solid #212121 !important;
    color: #fff !important;

    &:hover {
      background-color: #212121 !important;
      border: 1px solid #212121 !important;
      color: #fff !important;
    }
  }

  .extract_tbl_search input {
    background-color: #212121 !important;
    color: #fff !important;
    border: 1px solid #212121 !important;
  }

  .btn_ext_tbl {
    background-color: #212121 !important;
  }

  .btn_ext_tbl svg {
    stroke: #fff !important;
  }

  .column-modal {
    background-color: #212121 !important;
    border: 1px solid #212121 !important;
  }

  .col_heading_h5 {
    color: #fff !important;
  }

  .column-search {
    color: #fff !important;
    background-color: #171717 !important;
    border: 1px solid #171717 !important;
  }

  .purchae_reqest {
    color: #fff !important;
  }

  .card_procrt {
    background-color: #171717 !important;
  }

  .card_procrt b,
  .card_procrt p {
    color: #fff !important;
  }

  .proctment_table thead tr th {
    background-color: #212121 !important;
    border: 1px solid #484848 !important;
  }

  .proctment_table tbody tr td {
    border: 1px solid #484848 ;
  }

  .nigtiaton_cards {
    box-shadow: none !important;
  }

  .proctment_table thead tr th div {
    color: #777777 !important;
  }

  .search_lbl_companies input {
    background-color: #212121 !important;
    color: #fff !important;
  }

  .search_lbl_companies input::placeholder {
    color: #777777 !important;
  }

  .search_lbl_companies svg {
    stroke: #777777 !important;
  }

  .discover_headings2,
  .discover_headings + p {
    color: #8c8c8c !important;
  }

  .card_discovers {
    background-color: #171717 !important;
  }

  .comnies_logos p {
    color: #fff !important;
  }

  .compnies_despt p {
    color: #8c8c8c !important;
  }

  .dropdownlistcompanies {
    background-color: rgba(95, 94, 94, 0.1);
  }

  .card_srched_conpnies {
    background-color: rgba(108, 108, 108, 0.1);
  }

  .card_srched_conpnies div p {
    color: #fff !important;
  }

  .chat-header {
    color: #fff !important;
  }

  .readline-btn {
    color: #fff !important;
  }

  .submenu .logout-button {
    background-color: #212121 !important;
  }

  .notification-dropdown {
    background-color: #212121 !important;
  }

  .notic_heading,
  .no-notifications {
    color: #fff !important;
  }

  .close_btn_noti {
    background-color: transparent !important;
  }

  .lig {
    display: none;
  }

  .dark {
    display: block !important;
  }

  .modal-content {
    background-color: #212121 !important;
  }

  .modal-headings {
    color: #fff !important;
  }

  .report_selects2 select {
    background-color: #121212 !important;
    border: none !important;
    color: #777777 !important;
  }

  .report_selects2 svg,
  .upload-drag svg {
    stroke: #777777 !important;
  }

  section.file_upload_section {
    border: 2px dashed #777777;
  }

  .upload-limit,
  .upload-text {
    color: #777777 !important;
  }

  .lblselct {
    color: #777777 !important;
    border: 1px solid #777777 !important;
  }

  .term_s {
    color: #fff !important;
  }

  .term_s span a {
    color: #fff !important;
    font-family: SF-Pro-Display-Bold;
  }

  .Modal {
    background-color: #212122 !important;
  }

  .inptcmn {
    background-color: #121212 !important;
    color: #fff !important;
    border: none !important;
  }

  .ReactModalPortal .Overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .password-settings h2 {
    color: #fff !important;
  }

  .password-settings form {
    background-color: #212121 !important;
  }

  .password-settings label {
    color: #777777 !important;
  }

  .password-field input {
    background-color: #121212 !important;
    border: none !important;
    color: #fff !important;
  }

  .password-field .password-toggle {
    border-left: none !important;
  }

  .risk-dropdown,
  .pause-input,
  .email-input {
    background-color: #212121 !important;
    border: none !important;
    color: #fff !important;
  }

  .inti_imgs_icons {
    color: #fff !important;
  }

  input:checked + .slider {
    background-color: #0faf35 !important;
  }

  .chat-input input {
    background-color: #212121 !important;
    border: none !important;
  }

  .chat-messages {
    background-color: #212121 !important;
  }

  .chat-input button {
    color: #fff;
  }

  .readline-btn-select {
    background-color: #212121 !important;
    color: #fff !important;
    border: 1px solid #212121 !important;
  }

  .readline-btn-select svg {
    stroke: #fff !important;
  }

  .readline-btn-down {
    background-color: #0c0c0c !important;
  }

  .notes-section textarea {
    color: #fff !important;
    outline: none !important;
    border: none !important;
  }



  .btn_mdl_close {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }

  .extct_mdl_ul ul {
    color: #fff !important;
  }

  .bg_dark_trnd {
    background-color: transparent !important;
  }
  .fix_hei_pre {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    border-left: 2px solid #fff !important;
  }

  .invite_heading,
  .lbl2 {
    color: #fff !important;
  }

  .inv_input {
    background-color: #212121 !important;
    color: #fff !important;
    border-radius: 8px !important;
    border: none !important;
    outline: none !important;
  }

  .btn_decline2 {
    background-color: transparent !important;
    border: 1px solid #777777 !important;
    color: #777777 !important;
  }

  .btn_approve {
    background-color: #777777 !important;
    color: #fff !important;
    border: 1px solid #777777 !important;
  }

  .close_btn_canvas,
  .btn_canvs {
    background-color: #070707 !important;
  }
  .close_btn_canvas svg,
  .btn_canvs svg {
    stroke: #fff !important;
  }
  .heading_convss,
  .table_det_canv tr th,
  .summerycarrd h2,
  .table_det_canv tr td:nth-child(2) {
    color: #fff !important;
  }
  .table_det_canv,
  .table_det_canv tr td {
    background-color: #212121 !important;
  }
  .para3,
  .para4 {
    color: #fff !important;
  }

  .rolelabl {
    border: 1px solid #fff;
  }

  .rolenames,
  .rolesdiscription,
  .user_name,
  .role_ul li {
    color: #fff !important;
  }

  .radbtn {
    border: 1px solid #fff;
  }

  .tags_team {
    color: #cce8ea;
  }
  .modal-title {
    color: #fff !important;
  }
  .airisktables li button.active {
    background-color: #121212 !important;
    color: #fff !important;
  }
  .airisktables li button:hover {
    background-color: #121212 !important;
    color: #fff !important;
  }

  .tabss_cnts {
    background-color: transparent !important;
  }
  .convert-button {
    background-color: #000 !important;
  }
  .file-upload-label {
    background-color: #121212 !important;
    color: #fff !important;
  }
  .tools-nav .active {
    background-color: #000 !important;
    color: #fff !important;
  }
  .cnverts_hsds {
    color: #fff !important;
  }

  .integrations_tbs_de {
    color: #fff !important;
  }

  .styled-dropdown {
    border: none !important;
    background-color: #121212 !important;
    color: #fff !important;
  }

  .download-report-btn {
    color: #fff !important;
    background-color: #000 !important;
  }
  .paslbl {
    color: #fff !important;
  }

  .nptpass {
    color: #fff !important;
    background-color: #070707 !important;
  }
  .linkgen {
    color: #fff !important;
    border: 1px solid #070707 !important;
    background-color: #070707 !important;
  }
  .copylinks {
    color: #fff !important;
    border: 1px solid #fff !important;
  }

  .clr_wht_mde {
    color: #fff !important;
  }

  .ai_explnti {
    font-size: 14px !important;
    color: #fff !important;
  }

  .selct_text {
    color: #9e9e9e !important;
    font-size: 13px !important;
  }

  .role_ul li::before {
    background-image: url(../check2.svg);
  }

  .auth-container:has(.terms-container) {
    background-color: transparent !important;
  }
  .terms-container h2 {
    color: #fff !important;
  }
  .terms-container p {
    color: #fff !important;
    text-transform: lowercase;
  }

  .terms-container h1 {
    color: #fff !important;
  }

  .terms-container ol li {
    color: #fff !important;
  }

  .auth-container:has(.privacy-container) {
    background-color: transparent !important;
  }

  .privacy-container h2 {
    color: #fff !important;
  }
  .privacy-container p {
    color: #fff !important;
    text-transform: lowercase;
  }

  .privacy-container h1 {
    color: #fff !important;
  }

  .privacy-container ul li {
    color: #fff !important;
  }
}

.terms-container ol {
  padding: 0;
}
.tltp_btn {
  position: relative;
}
.tooltips_view {
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #000;
  border-radius: 3px;
  padding: 3px 10px;
  color: #fff;
  font-size: 10px;
  display: none;
}

.tltp_btn:hover .tooltips_view {
  display: block;
}

.cnverts_hsds {
  color: #000 !important;
  font-size: 18px !important;
}
.download-report-btn {
  color: #fff !important;

  background-color: #000 !important;
}
.tabss_cnts {
  background-color: transparent !important;
}

.rreqaction {
  width: calc(100vw - 308px) !important;
  overflow: scroll !important;
}

.btn_mdl_close {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}
.btn_mdl_close svg {
  stroke: #000;
}
.min-hei-100vh {
  min-height: 100vh !important;
}



.inti_imgs_icons img {
  margin-right: 10px;
  display: inline-block;
}

.logo_wrps {
  width: 200px;
  height: 74px;
  background-image: url(../img/newlogo-svg.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: auto;
}

.password-field {
  margin-top: 5px;
}

.mrgbtm {
  margin-bottom: 10px;
  background-size: 150px !important;
}

.mode_theme_btn {
  background-color: #eeeff4 !important;
  font-size: 10px !important;
  border-radius: 100px;
  padding: 0;
  border: none !important;
  color: #70707d;
  text-transform: uppercase !important;
  font-family: SF-Pro-Display-Semibold;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lig {
  display: block;
}

.dark {
  display: none;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button.user svg {
  height: 15px;
  width: 15px;
}

.w-100 {
  font-weight: 100;
}

.w-200 {
  font-weight: 200;
}

.w-300 {
  font-weight: 300;
}

.w-400 {
  font-weight: 400;
}

.w-500 {
  font-weight: 500;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700 !important;
}

.w-800 {
  font-weight: 800;
}

.w-900 {
  font-weight: 900;
}

html,
body {
  background-color: #f9fafb !important;
}

.wrapper {
  min-height: 100vh;
  background-color: #f9fafb;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #fbfcfd;
  width: 220px;
  border-right: 1px solid rgba(112, 115, 147, 0.1);
}

.logo_wrap {
  padding: 12px 0;
  border-bottom: 1px solid rgba(112, 115, 147, 0.1);
}

.logo_wrap img {
  width: 130px;
  display: block;
  margin: auto;
}

.menu-items ul {
  padding: 0;
  margin: 0;
}

.menu-items ul li {
  list-style-type: none;
  font-size: 13px;
  color: #1d1d25;
  padding: 10px 13px;
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
  font-weight: 500 !important;
}

.count {
  position: absolute;
  background-color: #e9ecfb;
  font-size: 13px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 24px;
  right: 10px;
  top: 8px;
}

.content-area {
  padding: 15px;
  margin: inherit;
}

.menu-items ul li:hover {
  background-color: #f3f4f7;
  border-radius: 10px;
  color: #000;
}

.menu-items ul li.active {
  background-color: #f3f4f7;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

.menu-items ul li svg {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.padding-left-220 {
  padding-left: 220px;
}

.search-header {
  width: 650px;
  display: block;
  position: relative;
}

.search-header svg {
  position: absolute;
  stroke: rgba(0, 0, 0, 0.5);
  top: 14px;
  left: 15px;
  height: 16px;
  width: 16px;
}

a {
  text-decoration: none !important;
}

.search-header input {
  width: 100%;
  margin: auto;
  display: block;
  border: 1px solid #e7e8ee;
  border-radius: 8px;
  padding: 11px 38px;
  font-size: 13px;
  color: #23232a;
  background-color: #fcfcfe !important;
  font-family: SF-Pro-Display-Medium !important;
}

.search-header input::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.title {
  font-weight: 400;
  font-size: 20px !important;
}

.dash_lbl_sele svg {
  position: absolute;
  top: 5px;
  right: 0;
}

.cards {
  margin-top: 5px;
  background-color: #ffffff;
  border: 1px solid rgba(112, 115, 147, 0.1);
  border-radius: 8px;
  padding: 10px 15px;
}

.font-10 {
  font-size: 10px;
}

.dashboard_selct {
  border: none;
  font-size: 14px !important;
  border-bottom: 1px solid grey;
  padding-bottom: 4px;
  padding-right: 25px;
  appearance: none;
  border-radius: 0;
  background-color: transparent !important;
}

.font-13 {
  font-size: 12px;
}

.color-l-grey {
  color: #2e2e2e;
}

.color-l-grey2 {
  color: #2f2f2f;
}

.tags {
  display: flex;
  align-items: center;
  color: rgb(163, 37, 37);
  font-size: 10px;
  border: 1px solid rgb(216, 216, 216);
  padding: 1px 3px;
  border-radius: 8px;
  margin-left: 10px;
}

.border-right {
  border-right: 1px solid rgb(226, 226, 226);
}

.export_btn {
  border: none;
  color: #1f1f22;
  font-size: 12px;
  background-color: transparent;
}

.taggs2 span {
  border-radius: 100px;
  background-color: rgb(206, 255, 206);
  color: green;
  font-size: 10px;
  padding: 3px 10px;
}

.taggs2 {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(53, 52, 52);
  font-weight: 600;
}

.taggs3 span {
  border-radius: 100px;
  background-color: rgb(255, 221, 221);
  color: rgb(128, 0, 0);
  font-size: 10px;
  padding: 3px 10px;
}

.taggs3 {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(53, 52, 52);
  font-weight: 600;
}

.taggs4 span {
  border-radius: 100px;
  background-color: rgb(181, 181, 181);
  color: rgb(247, 247, 247);
  font-size: 10px;
  padding: 3px 10px;
}

.taggs4 {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(53, 53, 53);
  font-weight: 600;
}

.apexcharts-toolbar {
  display: none !important;
}

table {
  width: 100%;
}

th {
  font-size: 14px;
  color: rgb(34, 31, 31);
  text-align: left;
  font-weight: 500;
  border-bottom: 1px solid rgb(217, 212, 212);
  padding-bottom: 13px;
}

td {
  font-size: 13px;
  color: rgb(43, 42, 42);
  text-align: left;
  font-weight: 400;
  border-bottom: 1px solid rgb(197, 197, 197);
  padding-bottom: 13px;
  padding-top: 13px;
}

.init {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: rgb(252, 58, 90);
  background-color: rgb(253, 208, 215);
  font-weight: 600;
  font-size: 20px;
}

.init2 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: rgb(165, 138, 30);
  background-color: rgb(248, 225, 169);
  font-weight: 600;
  font-size: 20px;
}

.draft {
  background-color: rgb(245, 245, 245);
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
}

.aprove {
  background-color: rgb(206, 255, 244);
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(69, 71, 71) !important;
}

.sign {
  background-color: rgb(250, 223, 223);
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(69, 71, 71) !important;
}

.nigo {
  background-color: rgb(223, 237, 250);
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(69, 71, 71) !important;
}

.prof {
  width: 40px;
  border-radius: 100%;
}

.lasttr td {
  border: none;
}

.header-flex {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.header-flex > :nth-child(1) {
  display: none;
}

.border-lgrey {
  border: 1px solid rgb(225, 225, 225);
  border-radius: 8px;
}

.bg-white {
  background-color: #fff !important;
}

.title_doc {
  font-weight: 800;
  font-size: 24px;
}

.sub_para {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.md_lbl {
  border: 1px dashed #6b7280;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 10px;
  color: #6b7280;
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.btn_uplo_doc {
  background-color: #0c0c0c;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 50px;
  display: block;
  margin: auto;
  border: none;
}

.btn_uplo_doc:hover {
  background-color: #181818 !important;
}

.readline-btn {
  background-color: transparent;
  color: #141414;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 600;
  padding: 7px 20px;
  display: block;
  margin: auto;
  margin-left: 0px;
  border: 1px solid #141414;
}

.readline-btn:hover {
  background-color: #fff;
}

.readline-btn svg {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.font-sml {
  font-size: 20px !important;
}

.btn_close_x {
  border: none;
  width: 20px;
  background-color: transparent !important;
  cursor: pointer;
}

.btn-gred {
  background: #1e1e25;
  border: 1px solid #000;
  width: 160px;
  margin-top: 0px;
  border-radius: 50px;
  color: #fff !important;
  font-size: 13px;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  align-items: center;
  gap: 10px;
}

.btn-gred:hover {
  background: #222121 !important;
  border: 1px solid #222121 !important;
  border-radius: 50px;
  color: #fff !important;
  padding: 10px 20px !important;
}

.font-11 {
  font-size: 11px !important;
}

.inviteteam_mem {
  background-color: rgba(112, 115, 147, 0.058823529411764705);
  color: #363644;
  font-size: 14px;
  border: none;
  border-radius: 100px;
  padding: 8px 20px;
  margin-left: 25px;
  display: flex;
  align-items: center;
}

.h3_inviteteam {
  font-size: 26px;
  font-weight: 400;
}

.inv_h5 {
  font-size: 14px;
  font-weight: 400;
}

.intil {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  color: #1e1e2a;
  background-color: #cce8ea;
  font-size: 12px;
}

.bg_aqwa_green {
  background-color: #cce8ea;
}

.user_name {
  margin-bottom: 3px;
  font-size: 14px;
  color: #363644;
}

.user_email {
  margin-bottom: 0;
  font-size: 12px;
  color: #70707d;
}

.para2 {
  font-size: 12px;
  color: #70707d;
}

.bg_aqwa_grey {
  background-color: #dddde5;
}

.para2 span {
  color: #363644;
}

.btn_decline {
  color: #d03275;
  font-size: 14px;
  border-radius: 100px;
  padding: 8px 20px;
  background-color: rgba(112, 115, 147, 0.058823529411764705);
  border: none;
}

.btn_decline2 {
  color: #000000;
  font-size: 14px;
  border-radius: 100px;
  padding: 8px 20px;
  background-color: rgba(255, 255, 255, 0.059);
  border: 1px solid #000000 !important;
}

.btn_decline2:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.btn_approve {
  background-color: #000 !important;
}

.btn_approve {
  color: #fff;
  font-size: 14px;
  border-radius: 100px;
  padding: 8px 20px;
  background-color: #000000;
  border: 1px solid #000000 !important;
  margin-left: 10px;
}

.text-right {
  text-align: right;
}

.adm_inv {
  font-size: 13px;
  color: #363644;
}

.dot_sep {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  display: inline-block;
}

.no_filter {
  color: #70707d;
  font-size: 14px;
  display: inline-block;
  border-left: 1px solid rgb(223, 223, 223);
  padding-left: 20px;
  font-weight: 400;
  margin-left: 20px;
}

.tab_head {
  font-size: 11px;
  color: #70707d;
  margin-bottom: 0;
}

.bor_btm {
  border-bottom: 0.5px solid #e0e0e0;
}

.tags_team {
  color: #363644;
  font-size: 11px;
  border: 1px solid #cce8ea;
  border-radius: 5px;
  padding: 2px 10px;
  font-weight: 400;
  margin-top: 7px;
  display: inline-block;
}

.time_tema {
  color: #535461;
  font-size: 13px;
  margin-top: 10px;
}

.tabs_row:hover {
  background-color: rgb(250, 250, 250);
}

.tabs_row:hover .col-4 .tags_team {
  background-color: #cce8ea;
}

.bg_purple {
  background-color: #dddbfa;
}

.purple {
  border: 1px solid #dddbfa;
}

.tabs_row:hover .col-4 .tags_team.purple {
  background-color: #dddbfa;
}

.nav_brd_left {
  border-left: 2px solid rgba(112, 115, 147, 0.2);
  width: 370px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  font-size: 14px;
  color: #363644;
  font-weight: 500;
  border-radius: 0;
  border-left: 2px solid #000000;
  margin-left: -2px;
  text-align: left;
}

.nav-pills .nav-link {
  background-color: transparent;
  font-size: 14px;
  color: #9d9da8;
  font-weight: 400;
  border-radius: 0;
  padding: 10px 25px;
  margin-left: -2px;
  text-align: left;
}

.invite_heading {
  font-size: 30px;
  color: #363644;
  font-weight: 400;
}

.max_width_1000 {
  width: 500px;
}

.lbl2 {
  font-size: 12px;
  color: #535461;
  display: block;
  margin-bottom: 7px;
}

.inv_input {
  border: 1px solid rgba(112, 115, 147, 0.1607843137254902);
  border-radius: 8px;
  color: #000;
  padding: 8px 12px;
  width: 100%;
}

.para3 {
  color: #363644;
  font-size: 14px;
}

.para3 span {
  color: #5266eb;
}

.para4 {
  font-size: 13px;
  color: #535461;
}

.rolelabl {
  border: 1px solid rgba(112, 115, 147, 0.1607843137254902);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.roleinput {
  position: absolute;
  opacity: 0;
}

.rolenames {
  font-size: 14px;
  color: #535461;
}

.rolesdiscription {
  font-size: 11px;
  color: #70707d;
}

.radbtn {
  min-height: 17px;
  min-width: 17px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid rgba(112, 115, 147, 0.1607843137254902);
}

.roleinput:checked + .rolelabl {
  border: 1px solid #5266eb;
}

.roleinput:checked + .rolelabl .radbtn {
  min-height: 17px;
  min-width: 17px;
  border: 5px solid #5266eb;
}

.roleinput:checked + .rolelabl .rolenames {
  color: #1e1e2a;
}

.cardreview {
  border: 1px solid rgba(112, 115, 147, 0.1607843137254902);
  padding: 15px 20px;
  border-radius: 10px;
}

.mt-9p {
  margin-top: 9px;
}

.parab6 {
  font-size: 12px;
  color: #70707d;
}

.role_ul {
  padding-left: 0;
  margin: 0;
}

.role_ul li {
  list-style-type: none;
  font-size: 13px;
  color: #1e1e2a;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.role_ul li::before {
  content: '';
  display: inline-block;
  height: 18px;
  width: 18px;
  background-size: 100%;
  background-position: center;
  margin-right: 10px;
  background-image: url(../check3.svg);
}

.c_btn_cross {
  background-color: transparent;
  border: none;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.col1 {
  width: 230px;
  display: flex;
  flex-direction: column;
}

.file-upload-label {
  margin: 0px 0px 10px 0px;
  display: block;
  cursor: pointer;
  border: 2px dashed rgba(107, 114, 128, 0.5);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  width: 100%;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.file-upload-label:hover {
  border-color: rgba(107, 114, 128, 0.8);
}

.upload-drag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-input {
  display: none;
}

.upload-text {
  color: #4b5563;
  font-weight: 500;
  margin-top: 15px;
}

.upload-limit {
  color: #6b7280;
  font-size: 14px;
}

.disabled-button {
  background-color: #d3d3d3;
  color: #808080;
  cursor: not-allowed;
  pointer-events: none;
}

.chart-container {
  position: relative;
}

.blur-chart {
  filter: blur(5px);
  /* Apply a blur effect */
  pointer-events: none;
  /* Disable interactions */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  z-index: 1;
  /* Make sure the overlay is on top */
}

.overlay-text {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.chart-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.sspn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeff4;
  border-radius: 3px;
  height: 19px;
  width: 19px;
  position: absolute;
  right: 42px;
  top: 12px;
}

.sspn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeff4;
  border-radius: 3px;
  height: 19px;
  width: 19px;
  position: absolute;
  right: 17px;
  top: 12px;
  font-size: 12px;
  color: rgba(6, 6, 6, 0.5);
}

.sspn svg {
  stroke: rgba(6, 6, 6, 0.5);
  height: 10px;
  width: 10px;
  top: 0;
  left: 0;
  position: static;
}

.dashcards p {
  font-size: 12px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashcards h4 {
  font-size: 18px !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.dashcards {
  height: 100%;
}

.btn-notwid {
  width: auto !important;
}

.btn_td_tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  padding: 7px;
  width: 100%;
  white-space: nowrap;
  margin: 0 !important ;
}
.max-min-wid {
  max-width: 100% !important;
  min-width: 100% !important;
}

.modal_close_btn {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}
.modal_close_btn svg {
  stroke: #000 !important;
}
.btn_slcts {
  padding: 5px 15px !important;
  margin: 0 !important;
  border-radius: 100px !important;
  font-size: 13px !important;
  font-family: SF-Pro-Display-Bold !important;
}

.btn_anylyzi {
  margin: 0 !important;
  padding: 6px 15px !important;
  background-color: #3a9d01 !important;
  font-family: SF-Pro-Display-Semibold !important;
  border-radius: 100px !important;
  font-size: 13px !important;
}
.btn_anylyzi:disabled {
  opacity: 0.3 !important;
}
.upper_case_textt {
  text-transform: uppercase !important;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.fixz-width {
  min-width: 1000px !important;
  max-width: 1000px !important;
}

.fix_hei_pre {
  height: 470px !important;
  overflow: auto !important;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px;
}

.clr_wht_mde {
  color: #000 !important;
}

.ai_explnti {
  font-size: 14px !important;
  color: #000 !important;
}

.selct_text {
  color: #000 !important;
  font-size: 13px !important;
}

.btntds {
  min-width: 170px;
}

.btn_td_tabs:hover {
  background-color: #f0f0f0 !important;
}

.btn_td_tabs svg {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}

.set_page_hadiing {
  color: #000 !important;
  text-transform: capitalize !important;
}

.settings-item {
  padding: 20px 0 !important;
  max-width: 600px;
}

.settings-item span {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.settings-item div img {
  width: 50px !important;
  height: 50px !important;
}

.settings-item div button {
  background-color: transparent;
  display: block;
  color: rgb(106, 12, 12);
  font-size: 13px;
  font-weight: 500;
  padding: 0;
}

.settings-item div {
  width: 200px;
}

.settings-item span:nth-child(2) {
  width: 200px;
}

.btn-in-team {
  width: auto !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center !important;
}

.color-blk {
  color: #000 !important;
}

.login-heading {
  font-family: SF-Pro-Display-Bold !important;
  color: #000000 !important;
  margin-top: 0;
}

.button-signup {
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #000000;
  font-family: SF-Pro-Display-Medium !important;
  font-size: 14px;
  margin: 5px 0 !important;
}

.button-signup span {
  font-family: SF-Pro-Display-Medium !important;
}

.button-signup:hover {
  background-color: transparent !important;
}

.button-signup span b {
  color: rgb(107, 3, 3);
  letter-spacing: 0.4px;
}

.button-signup span b:hover {
  border-bottom: 1px solid rgb(107, 3, 3);
}

.auth-container {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  min-width: 450px !important;
}

.auth-wrapper {
  min-height: 100vh;
  padding: 30px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-text {
  font-size: 16px !important;
}

.auth-btn {
  font-family: SF-Pro-Display-Medium !important;
}

.company-name {
  text-transform: capitalize !important;
}

.down-icons {
  width: 10px !important;
  height: 10px !important;
}

.modal-headings {
  font-size: 16px !important;
  font-family: SF-Pro-Display-Bold !important;
  color: #000 !important;
}

.cards_headings {
  font-size: 16px !important;
  font-family: SF-Pro-Display-Semibold !important;
  color: #000 !important;
}

.task_tabl tr td,
.task_tabl tr th {

  padding: 10px 15px ;
  
}

.task_tabl tr .padzero {
  padding: 0 0 !important;
}

.task_tabl tr th {
  font-size: 12px !important;
}

.button-send {
  background-color: #f8f8f8 !important;
  border-radius: 5px;
  color: #262626;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.settings-item span {
  font-family: SF-Pro-Display-Semibold !important;
}

.parset {
  font-size: 11px !important;
  color: #808080 !important;
  font-family: SF-Pro-Display-Regular !important;
  margin: 3px 0;
}

.fnt-capt {
  text-transform: capitalize !important;
}

.settings-item div button {
  font-family: SF-Pro-Display-Medium !important;
  margin: 5px 0 !important;
}

.settings_phead {
  font-family: SF-Pro-Display-Regular !important;
  font-size: 20px !important;
  color: #000 !important;
}

.settings-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.bord_non {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.go-back {
  background-color: #000 !important;
  font-family: SF-Pro-Display-Medium !important;
  font-size: 13px !important;
  color: #ffffff !important;
  padding: 10px !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  width: 165px !important;
}

.go-back2 {
  background-color: #ececec !important;
  font-family: SF-Pro-Display-Medium !important;
  font-size: 13px !important;
  color: #282828 !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  width: auto !important;
  border-radius: 100px !important;
  padding: 8px 18px 8px 10px !important;
}

.go-back2 svg {
  stroke: #282828;
  height: 17px;
  width: 17px;
  margin-right: 3px;
}

.notsheading {
  font-family: SF-Pro-Display-Medium !important;
  font-size: 17px !important;
}

.notes-textarea {
  height: 250px !important;
  width: 100% !important;
  border: 1px solid #000 !important;
}

.notes-section {
  display: inline-block !important;
}

.paspro {
  font-family: SF-Pro-Display-Medium !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.paslbl {
  display: block;
  font-family: SF-Pro-Display-Medium !important;
  color: #000000 !important;
  font-size: 13px !important;
  margin-bottom: 3px;
}

.nptpass {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-size: 13px !important;
}

.linkgen {
  font-size: 12px;
  min-height: 50px;
  max-height: 100px;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 4px;
  overflow: scroll;
}

.copylinks {
  padding: 0 !important;
  border: 1px solid #000 !important;
  color: #000000 !important;
  background-color: transparent !important;
  padding: 5px !important;
  font-size: 12px !important;
  margin-top: 5px 0 !important;
}

.c_btn_cross:hover {
  background-color: transparent !important;
}

.viewdocsec {
  flex: 2;
  background-color: rgb(237, 237, 237);
  min-height: 100vh;
  border-radius: 10px;
}

.docdetsilsec {
  width: 350px;
}

.docdetsilsec div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.docdethead {
  margin: 0;
  font-family: SF-Pro-Display-Bold !important;
  font-size: 16px;
}

.docdettable {
  width: 100%;
}

.docdettable tr td {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px 0 !important;
  font-family: SF-Pro-Display-Medium !important;
}

.enterpaeewordwrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enterpaeewordwrap div {
  width: 300px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px;
}

.inp-paas {
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
}

.inptcmn {
  display: block;
  width: 100%;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.dashcards h4 {
  font-family: SF-Pro-Display-Bold !important;
  font-size: 22px !important;
}

select {
  appearance: none !important;
}

.report_selects select {
  min-width: 150px;
  font-size: 14px !important;
  padding-right: 40px !important;
}

.monthselect {
  width: 130px !important;
}

.report_selects {
  position: relative;
}

.report_selects svg {
  position: absolute;
  top: 36px;
  right: 10px;
  width: 18px;
  height: 18px;
}

.report_selects2 select {
  font-size: 14px !important;
}

.report_selects2 {
  position: relative;
}

.report_selects2 svg {
  position: absolute;
  top: 36px;
  right: 10px;
  width: 18px;
  height: 18px;
}

/* From Uiverse.io by solanodz */
/* The switch - the box around the slider */
.switch2 {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  background-color: rgb(240, 240, 240);
  transition: 0.5s;
  border-radius: 0px;
  border: 1px solid #000000;
}

.slider2:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 0px;
  left: 4px;
  bottom: 0.3em;
  background-color: rgb(255, 255, 255);
  border: 1px solid #000000;
  transition: 0.5s;
}

.switch2 input:checked + .slider2 {
  background-color: rgb(240, 240, 240);
  border: 1px solid #989898;
}

.switch2 input:focus + .slider2 {
  border: 1px solid #989898;
}

.switch2 input:checked + .slider2:before {
  transform: translateX(1.5em);
}

.pre-view {
  position: absolute;
  font-size: 16px !important;
  color: #525252;
  right: 12px;
  top: 5px;
  z-index: 9999;
}

.full-view {
  position: absolute;
  font-size: 16px !important;
  color: #525252;
  left: 12px;
  top: 5px;
  z-index: 99999;
}

.previewtool {
  padding: 0 3px;
  background-color: #000;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
  display: none;
}

.pre-view:hover + .previewtool {
  display: block;
  position: absolute;
  top: -14px;
  right: 0px;
}

.fullviewtool {
  padding: 0 3px;
  background-color: #000;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
  display: none;
}

.full-view:hover + .fullviewtool {
  display: block;
  position: absolute;
  top: -14px;
  left: 0px;
}

.tabl_dis_nts tr td {
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #a6a6a6;
}

.tabl_dis_nts tr td:nth-child(1) {
  min-width: 130px !important;
  font-family: SF-Pro-Display-Medium !important;
}

.listserdoc {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9 !important;
  top: 40px;
}

.listserdoc ul {
  padding: 0;
  margin: 0;
}

.listserdoc ul li {
  list-style-type: none;
  font-size: 13px;
  color: #1e1e2a;
  font-family: SF-Pro-Display-Medium !important;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.listserdoc ul li svg {
  height: 15px;
  width: 15px;
  stroke: #1d1d25;
  margin-right: 5px;
  position: static !important;
}

.inititab {
  display: flex;
  align-items: center;
}

.inititab span {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 100%;
  font-family: SF-Pro-Display-Bold !important;
  background-color: rgb(193, 255, 244);
}

.inititab div p {
  margin-bottom: 0;
  margin-top: 0;
  font-family: SF-Pro-Display-Medium !important;
  color: #000 !important;
}

.inititab div b {
  margin-bottom: 0;
  margin-top: 0;
  font-family: SF-Pro-Display-regular !important;
  color: grey !important;
  font-size: 12px;
}



.teamtable tr th {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Regular !important;
background-color: #eeeff4 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.task_tabl tr th {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Regular !important;
  background-color: #eeeff4 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
 
}

.task_tabl tr th:nth-child(1) {
  border-radius: 7px 0 0 0 !important;
}

.task_tabl tr th:nth-child(7) {
  border-radius: 0 0 0 0 !important;
}

.roletags {
  color: rgb(62, 62, 62);
  border: 1px solid rgb(32, 144, 123);
  font-size: 12px !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  text-transform: capitalize !important;
}

.auth-container:has(.terms-container) {
  background-color: transparent !important;
  border: none !important;
}

.auth-container:has(.privacy-container) {
  background-color: transparent !important;
  border: none !important;
}

.privacy-container h2,
.terms-container h2 {
  color: #000 !important;
}

.removebuttns {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(255, 230, 230) !important;
  padding: 0 !important;
}

.removebuttns svg {
  stroke: rgb(124, 12, 12);
  height: 15px;
  width: 15px;
}

.teamtable tr th:nth-child(1) {
  border-radius: 7px 0 0 0 !important;
}

.teamtable tr th:nth-child(4) {
  border-radius: 0 7px 0 0 !important;
}

.teamtable tr td {
  background-color: #fff !important;
}

.ReactModalPortal .Overlay {
  background-color: rgba(255, 255, 255, 0.7);
}

.password-settings form {
  box-shadow: none !important;
}

.ReactModal__Content .close-modal {
  margin-top: 10px !important;
}

.wid300px {
  width: 350px !important;
}

.newsearchlist {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgba(253, 253, 253, 1);
  padding: 10px 0px;
  width: 100%;
  position: absolute;

  top: 47px !important;
  border-radius: 10px;
}

.newsearchlist p {
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 11px !important;
  color: grey;
}

.newsearchlist2 {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgba(253, 253, 253, 1);
  padding: 10px 0px;
  width: 93%;
  position: absolute;

  top: 97px !important;
  border-radius: 10px;
}

.newsearchlist2 p {
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 11px !important;
  color: grey;
}

.newsearbtn {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  font-size: 11px;
  padding: 4px 10px;
  color: rgb(82, 82, 82);
  margin-left: 0 !important;
  margin-right: 11px !important;
  font-family: SF-Pro-Display-Medium !important;
  display: flex;
  align-items: center;
}

.newsearbtn:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgb(82, 82, 82) !important;
}

.wrapnewbtns {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding-bottom: 5px !important;
}

.newsearbtn svg {
  width: 13px;
  height: 13px;
  stroke: rgb(47, 47, 47);
  margin-right: 5px;
  position: static !important;
}

.smartpromptbtn {
  padding: 0;
  margin: 0;
}

.smartpromptbtn li {
  list-style-type: none !important;
  font-size: 12px;
  color: rgb(64, 64, 64);
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.smartpromptbtn li:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

.smartpromptbtn li svg {
  position: static !important;
  margin-right: 5px !important;
}

.smartpromptbtn li:hover svg {
  stroke: #000000 !important;
}

.activelistlbl {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.activelistlbl svg {
  height: 16px;
  width: 16px;
}

.activelistlbl svg:nth-child(1) {
  display: block;
  color: rgb(40, 40, 40);
}

.activelistlbl svg:nth-child(2) {
  display: none;
  color: grey;
}

#activelistlbls {
  position: absolute;
  opacity: 0;
}

#activelistlbls:checked + .activelistlbl {
  background-color: rgb(15, 15, 15);
}

#activelistlbls:checked + .activelistlbl svg:nth-child(1) {
  display: none;
}

#activelistlbls:checked + .activelistlbl svg:nth-child(2) {
  display: block;
  stroke: #fff;
  height: 15px;
  width: 15px;
}

.redlineselct select {
  padding: 6px;
  border-radius: 5px;
}

.redtabletd td {
  font-size: 13px !important;
}

.dropdown-details {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  margin-top: 0px;
  border-radius: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropdown-details table tr td:nth-child(1) {
  color: #000 !important;
  font-family: SF-Pro-Display-Semibold !important;
  width: 290px !important;
}

.padzero {
  padding: 0 !important;
}

.docicons {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(0, 94, 255, 0.08);
}

.docicons svg {
  height: 16px;
  width: 16px;
  stroke: rgb(63, 113, 242);
}

.modheading {
  margin: 0 !important;
  font-size: 13px !important;
  color: #000 !important;
}

.modaldocuments {
  width: 550px !important;
}

.progressbar {
  height: 5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}

.progressbar span {
  height: 100%;
  background-color: #000;
  border-radius: 10px;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}

.paraMod {
  margin: 0;
  margin-bottom: 7px !important;
  color: #000 !important;
  font-size: 11px !important;
  font-family: SF-Pro-Display-Medium !important;
  margin-right: 30px;
}

.flex-2 {
  flex: 2;
}

.wrapdicon {
  width: 43px;
}

.documentcounts {
  position: absolute;
  padding: 1px 10px;
  border-radius: 20px;
  background-color: rgb(233, 255, 233);
  color: rgb(8, 74, 8);
  font-size: 10px;
  font-family: SF-Pro-Display-Medium !important;
  right: 0;
  top: 0;
}

.profoccupation {
  margin: 6px;
  padding: 0;
}

.profoccupation li {
  list-style-type: number;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  margin: 5px;
  font-size: 12px;
  font-family: SF-Pro-Display-Medium !important;
}

.modheading + p {
  font-size: 11px !important;
  margin: 4px 0;
  font-family: SF-Pro-Display-Medium !important;
  color: #000 !important;
}

td svg,
.activelistlbl {
  cursor: pointer !important;
}

.dropdown-details h5 {
  font-size: 15px;
  font-family: SF-Pro-Display-Semibold !important;
}

.dropdown-details p {
  font-size: 13px !important;
}

.serhea2 {
  width: 100% !important;
}

.serhea2 input {
  border-radius: 5px !important;
}

.rpt_tables tbody tr th {
  color: rgb(137, 137, 137);
  font-size: 12px !important;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.rpt_tables tbody tr th input {
  height: 13px;
  width: 13px;
}

.btn_filter {
  background-color: transparent !important;
  border: none !important;
  color: grey;
  font-size: 13px !important;
  padding: 5px !important;
}

.btn_filter svg {
  height: 17px;
  width: 17px;
}

.shortanswer {
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #5b5b5b;
  font-size: 13px !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 5px !important;
}

.shortanswer svg {
  height: 16px !important;
  width: 16px !important;
  stroke: rgb(75, 75, 75);
}

.shortanswer:hover {
  background-color: transparent !important;
}

.rpt_tables tbody tr th:nth-child(1) {
  width: 30px !important;
}

.container_chx {
  width: 61px;
  height: 31px;
  position: relative;
  margin-left: auto;
}

/* Hide default HTML checkbox */
.checkbox_chx {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch_chx {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #161616;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider_chx {
  width: 27px;
  height: 27px;
  position: absolute;
  left: calc(50% - 37px / 2 - 10px);
  top: calc(50% - 27px / 2);
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox_chx:checked + .switch_chx {
  background-color: #161616;
}

.checkbox_chx:checked + .switch_chx .slider_chx {
  left: calc(50% - 18px / 2 + 10px);
  top: calc(50% - 27px / 2);
}

.checkbox_chx:checked + .switch_chx + .charts_nms + .files_nms svg {
  stroke: #000 !important;
}

.checkbox_chx:not(:checked) + .switch_chx + .charts_nms svg {
  stroke: #000000 !important;
}

.charts_nms {
  position: absolute;
  top: 2px;
  left: 6px;
  z-index: 9999999;
}

.files_nms {
  position: absolute;
  top: 2px;
  right: 6px;
  z-index: 9999999;
}

.files_nms {
  position: absolute;
}

.charts_nms svg {
  height: 18px;
  width: 18px;
  stroke: rgb(255, 255, 255);
}

.files_nms svg {
  height: 18px;
  width: 18px;
  stroke: rgb(255, 255, 255);
}

label {
  cursor: pointer !important;
}

/* User home notification */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-bell {
  position: relative;
  cursor: pointer;
}

.cirnotisvg {
  background-color: #eeeff4;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bell-icon {
  font-size: 24px;
  color: #333;
}

.notification-count {
  position: absolute;
  top: -6px;
  right: -2px;
  background-color: rgb(117, 3, 3);
  color: white;
  font-size: 8px;
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #f9fafb;
}

.notification-dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 47px;
  width: 230px;
  overflow-y: auto;
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.notification-item {
}

.notification-item span {
  flex: 1;
  margin-right: 10px;
}

.notification-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 6px 10px;
}

.notification-actions button {
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.no-notifications {
  padding: 10px;
  text-align: center;
  font-size: 12px !important;
}

section.file_upload_section {
  border: 2px dashed rgba(107, 114, 128, 0.6);
  border-radius: 15px;
  padding: 25px 20px;
}

.lblselct {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(107, 114, 128, 0.6);
  font-size: 12px;
  color: rgb(33, 37, 45);
  font-family: SF-Pro-Display-Semibold !important;

  background-color: transparent;
  margin: 5px 0 !important;
}

.lblselct input {
  position: absolute;
  opacity: 0;
}

.lblselct svg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  stroke: 1px solid rgba(107, 114, 128, 0.6);
}

.lblselct img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.lblselct:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.repcardstabl {
  width: calc(100vw - 292px);
}

.tabrapes_scrl {
  overflow: scroll;
  width: 100%;
}

.rpt_tables th,
.rpt_tables td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tabrapes_scrl table {
  width: 3000px;
}

.tabrapes_scrl table tbody tr th {
  white-space: nowrap;
}

.btn-notwid:hover {
  transform: scale(1) !important;
  padding: 10px 20px !important;
  font-size: 13px !important;
}

.btn_upld_head {
  background-color: #171717;
  border: 1px solid #171717;
  border-radius: 100px;
  padding: 7px 20px;
  font-size: 13px;
}

.btn_upld_head:hover {
  background-color: transparent !important;
  color: #171717 !important;
}

.btn_upld_head:hover svg {
  stroke: #000 !important;
}

.notification-bell div svg {
  height: 15px;
  width: 15px;
  stroke: #70707d;
}

.notic_heading {
  font-size: 15px !important;
  color: #141414 !important;
  font-family: SF-Pro-Display-Semibold !important;
  margin: 0;
  padding: 0;
}

.not_card_head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close_btn_noti {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 20px;
  width: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_btn_noti svg {
  height: 14px !important;
  width: 14px !important;
  stroke: rgb(190, 190, 190);
}

.close_btn_noti:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.not_msgss {
  font-size: 13px;
  color: #141414;
  margin: 0;
  padding: 8px 0;
}

.not_msg_wraps {
  padding: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.mark-as-read {
  background-color: transparent;
  font-size: 11px;
  color: rgb(6, 68, 122);
  font-family: SF-Pro-Display-Medium;
  margin: 0;
  padding: 5px !important;
}

.mark-as-read:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.mark-as-read svg {
  height: 14px !important;
  width: 14px !important;
  margin-right: 5px;
}

.delete-notification {
  background-color: rgb(255, 239, 239);
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 !important;
}

.delete-notification svg {
  height: 12px !important;
  width: 12px !important;
  stroke: rgb(82, 3, 3) !important;
}

.discover_headings {
  font-size: 20px !important;
  color: #070707 !important;
  font-family: SF-Pro-Display-Semibold !important;
  margin-bottom: 0 !important;
}

.discover_headings2 {
  font-size: 14px !important;
  color: #3a3a3a !important;
  font-family: SF-Pro-Display-Semibold !important;
  margin-bottom: 0 !important;
}

.discover_headings + p {
  margin: 0 !important;
  font-size: 12px !important;
}

.card_discovers {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  cursor: pointer;
  height: 100%;
}

.comnies_logos img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

.comnies_logos p {
  margin: 0;
  color: #141414;
  font-size: 16px;
  font-family: SF-Pro-Display-Semibold !important;
}

.comnies_logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_infos {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  background-color: rgb(212, 239, 255);
}

.right_infos svg {
  height: 16px;
  width: 16px;
  stroke: rgb(12, 96, 149);
}

.compnies_despt p {
  font-size: 12px;
  color: #505050;
  font-family: SF-Pro-Display-Medium !important;
  margin-top: 20px;
  margin-bottom: 0;
}

.comnies_header img {
  width: 35px;
  border-radius: 10px;
  margin-right: 8px;
}

.comnies_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.comnies_header p {
  margin: 0;
  font-family: SF-Pro-Display-Semibold !important;
}

.insights_comp p {
  font-size: 13px;
  font-family: SF-Pro-Display-Semibold !important;
  color: #1e1e25;
}

.insgh_cards {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 133px;
}

.insgh_cards p {
  color: rgb(53, 6, 53);
  font-size: 12px;
  font-family: SF-Pro-Display-Semibold !important;
  margin: 0 5px;
  text-align: center;
}

.net2 {
  font-size: 10px !important;
  text-align: center;
  color: rgb(119, 119, 119) !important;
  font-family: SF-Pro-Display-Medium !important;
}

.ins_p2 {
  font-family: SF-Pro-Display-Semibold !important;
  font-size: 14px;
  text-align: center;
  color: purple !important;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.ins_p3 {
  color: rgb(65, 6, 65) !important;
  font-size: 25px;
  font-family: SF-Pro-Display-Medium !important;
  text-align: center !important;
  margin: 0 !important;
  font-weight: 400;
}

.fix_cards_wid {
  width: 465px !important;
  position: relative;
}

.card_loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  border-radius: 10px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.5);
}

.progs_cards {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

#documentModal {
  z-index: 9999 !important;
}

.prgs_brs_ul {
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  padding: 0;
  padding-top: 5px !important;
}

.prgs_brs_ul ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px !important;
  color: grey;
  font-family: SF-Pro-Display-Medium !important;
}

.prgs_brs_ul ul li {
  font-size: 11px !important;
  color: grey;
  font-family: SF-Pro-Display-Medium !important;
  list-style-type: none;
  margin: 0;
}

.bar_progline {
  position: relative;
  width: 100%;
  border-top: 2px solid purple;
  width: 100%;
}

.right-dot {
  background-color: purple;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  right: -2px;
}

.left-dot {
  background-color: purple;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  left: -2px;
}

.center-dot {
  width: 50px;
  border-radius: 10px;
  background-color: purple;
  height: 16px;
  top: -8px;
  left: 45%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-dot span {
  display: inline-block;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: pink;
}

.head_ctrs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.head_ctrs h4 {
  font-size: 15px !important;
  font-family: SF-Pro-Display-Semibold !important;
  color: #535353;
}

.head_ctrs h5 {
  font-size: 12px !important;
  color: purple;
  font-family: SF-Pro-Display-Medium !important;
}

.comp_dis_slc {
  text-align: center;
}

.comp_dis_slc svg {
  stroke: grey !important;
}

.comp_dis_slc p {
  font-size: 13px !important;
  font-family: SF-Pro-Display-Semibold !important;

  margin: 5px;
}

.suplrsdetails {
  padding: 15px !important;
}

.suplrsdetails h6 {
  font-family: SF-Pro-Display-Semibold !important;
  color: #535353;
  font-size: 15px;
}

.suplrsdetails p {
  font-size: 13px !important;
}

.comp_dis_slc button {
  width: 300px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  font-family: SF-Pro-Display-Semibold !important;
  color: gray;
}

.comp_dis_slc button svg {
  stroke: grey !important;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.detl_iins_dis p {
  margin: 0;
  font-size: 13px;
  color: #727272;
  font-family: SF-Pro-Display-Semibold !important;
}

.detl_iins_dis b {
  margin: 0;
  font-size: 13px;
  color: #23232a;
  font-family: SF-Pro-Display-Semibold !important;
}

.ins_addrs {
  font-size: 12px;
  color: rgb(179, 179, 179);
  display: flex;
  align-items: center;
  font-family: SF-Pro-Display-Semibold !important;
}

.ins_addrs svg {
  height: 13px;
  width: 13px;
}

.gotowebsitebtn {
  font-size: 13px !important;
  background-color: transparent;
  color: rgb(9, 37, 92);
  border: none;
  padding: 0 !important;
  display: flex;
  align-items: center;
  margin: 0;
}

.gotowebsitebtn svg {
  height: 13px;
  width: 13px;
  stroke: rgb(9, 37, 92);
}

.gotowebsitebtn:hover {
  background-color: transparent !important;
}

.pad-20px {
  padding: 0 10px !important;
}

.proc_btns_drp::after {
  height: 15px !important;
  width: 15px !important;
  background-size: 15px !important;
  display: none !important;
}

.proc_btns_drp {
  padding: 9px 20px !important;
  margin: 0 !important;
  border-radius: 10px !important;
  margin-bottom: 0 !important;
  position: relative;
}

.drop_opeen_click {
  position: absolute;
  height: 32px;
  width: 50px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 0px;
  border-radius: 10px;
}

.drop_opeen_click svg {
  height: 17px;
  width: 17px;
}

.drop_opeen_click svg {
  transform: rotate(-180deg);
  transition: 0.2s linear;
}

.drop_opeen_click.collapsed svg {
  transform: rotate(0deg);
  transition: 0.2s linear;
}

.border-radius-10 {
  border-radius: 10px !important;
  background-color: transparent !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.accordion-header:focus,
.accordion-item:focus {
  box-shadow: none;
  outline: none;
}

.disc_tab_btn {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  margin-left: 30px;
  color: #000000 !important;
  margin-bottom: 5px !important;
  padding: 8px 10px;
  border-radius: 10px;
  margin-top: 6px !important;
}

.disc_tab_btn:hover {
  background-color: #f3f4f7 !important;
  cursor: pointer;
}

.disc_tab_btn::before {
  content: '';
  display: inline-block;
  height: 4px;
  margin-right: 6px;
  width: 4px;
  border-radius: 100%;
  background-color: #23232a;
}

.search_lbl_companies {
  width: 100%;
  position: relative;
}

.search_lbl_companies input {
  width: 100%;
  padding: 11px 31px;
  padding-right: 10px !important;
  border-radius: 8px;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.5);
  background: #fcfcfe !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: SF-Pro-Display-Medium !important;
  outline: none !important;
}

.search_lbl_companies svg {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 14px;
  left: 10px;
  stroke: rgba(0, 0, 0, 0.5);
}

.search_lbl_companies input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.dropdownlistcompanies {
  width: 100%;
  border-radius: 5px;
  padding: 11px 8px;
  background: rgba(221, 221, 221, 0.2);
  border-radius: 8px;

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 46px;
  border: 1px solid rgba(15, 15, 15, 0.08);
}

.card_srched_conpnies {
  background-color: rgba(246, 246, 246, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  height: 100%;
}

.card_srched_conpnies img {
  height: 36px;
  width: 36px;
  border-radius: 8px;
}

.card_srched_conpnies div p {
  font-family: SF-Pro-Display-Semibold !important;
  color: #141414;
  font-size: 14px;
  margin: 0;
  line-height: 18px !important;
}

.card_srched_conpnies div p:nth-child(2) {
  font-family: SF-Pro-Display-Regular !important;
  color: #656565;
  font-size: 12px !important;
  margin: 0;
  line-height: 16px !important;
}

.chat-header h3 {
  font-size: 22px !important;
}

.card_srched_conpnies div u {
  font-family: SF-Pro-Display-Regular !important;
  color: #5c5c5c;
  font-size: 14px;
  margin: 0;
  text-decoration: none;
}

.cre_requ_btn {
  background-color: #000;
  color: #fff;
  font-size: 13px;
  font-family: SF-Pro-Display-Semibold !important;
  display: flex;
  align-items: center;
  margin-left: auto;
  border: 1px solid #000;
  padding: 7px 10px;
}

.cre_requ_btn:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.cre_requ_btn svg {
  height: 13px;
  width: 13px;
}

.purchae_reqest {
  font-size: 13px;
  color: #000;
  font-family: SF-Pro-Display-Semibold !important;
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
}

.card_procrt {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
}

.card_procrt p {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 13px;
  font-family: SF-Pro-Display-Medium !important;
  color: rgb(111, 111, 111);
}

.card_procrt p svg {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.card_procrt b {
  font-size: 30px !important;
  margin-bottom: 10px;
  display: block;
  margin-top: 6px;
}

.font_18px {
  font-size: 18px !important;
  color: #000 !important;
}

.proctment_table thead tr th {
  padding: 15px 20px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.proctment_table thead tr th div {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Semibold !important;
  color: #373737;
  display: flex;
  align-items: center;
}

.proctment_table thead tr th div span {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 10px;
  margin-left: 10px;
}

.proctment_table tbody tr td div p {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Medium !important;
  color: #373737;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proctment_table tbody tr td {
  padding: 10px 15px;
}

.nigtiaton_cards {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.nigtiaton_cards div img {
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.nigtiaton_cards div u {
  font-size: 15px !important;
  font-family: SF-Pro-Display-Semibold !important;
  margin-left: 8px;
  text-decoration: none;
}

.retemodal-head {
  font-size: 16px !important;
  font-family: SF-Pro-Display-Semibold !important;
}

.nigtiaton_cards div {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left !important;
}

.nigtiaton_cards section {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-top: 10px;
}

.request_create_form {
  width: 100%;
}

.request_create_form div label {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Medium !important;
  color: #252525 !important;
  display: block;
  margin-bottom: 5px;
}

.request_create_form div label + input {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.max-heig-modls {
  height: 600px !important;
  overflow: auto !important;
}

.btn_sumit_forms {
  background-color: #000;
  border: 1px solid #000;
  padding: 8px;
  font-size: 12px;
  font-family: SF-Pro-Display-Medium !important;
  color: #fff;
  width: 100%;
  margin: 15px 0 !important;
}

.btn_sumit_forms:hover {
  background-color: #fff;
  color: #000;
}

/* From Uiverse.io by barisdogansutcu */
.card_loader .svgsss {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.svgsss circle {
  fill: none;
  stroke: hsl(0, 0%, 7%);
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.chart_icons_btns {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.chart_icons_btns:hover {
  background-color: transparent !important;
  padding: 0 !important;
}

.chart_icons_btns svg {
  stroke: #000 !important;
}

.modal_close_buttons {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  margin-left: auto !important;
}

.modal_close_buttons:hover {
  background-color: transparent !important;
}

.modal_close_buttons svg {
  stroke: #000 !important;
}

.max-width-1000perce {
  width: 100% !important;
  max-width: 100% !important;
}

.for_pass_wrd a {
  font-size: 13px !important;
  color: #6d6c6c !important;
}

.readline-btn-down {
  background-color: #000 !important;
  font-size: 13px !important;
  padding: 7px 15px;
  border: 1px solid #000;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
}

.readline-btn-down svg {
  stroke: #fff;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.readline-btn-select {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 7px 15px;
  font-size: 13px !important;
}

.readline-btn-select svg {
  stroke: #000000;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.cards_headings22 {
  color: #000000 !important;
  margin: 0 !important;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
}

.settings-nav button {
  color: grey !important;
  font-size: 14px !important;
}

.settings-nav .active {
  background-color: #f3f4f7 !important;
  border-radius: 5px !important;
  padding: 7px 18px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-family: SF-Pro-Display-Semibold !important;
}

.settings-nav button {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 8px !important;
  padding: 7px 18px !important;
  color: #363644 !important;
  font-size: 13px !important;
  letter-spacing: 0.2px !important;
  display: flex !important;
  align-items: center !important;

  margin: 0;
  font-family: SF-Pro-Display-Semibold;
}

.settings-nav {
  gap: 0 !important;
}

.restet_pass_btn {
  background-color: transparent;
  display: block;
  color: rgb(106, 12, 12);
  font-size: 13px;
  font-weight: 500;
  padding: 0;
}

.for_passwrd a {
  font-size: 13px !important;
  color: rgb(108, 108, 108) !important;
}

.tb_contwo {
  background-color: transparent !important;
}

.card {
  border-radius: 8px !important;
}

.curs_point {
  cursor: pointer !important;
}

.wid_of_canvas {
  width: 550px !important;
  background-color: transparent !important;
  border: none !important;
}

.wrap_offcanv {
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
}

.body_canv {
  padding: 7px !important;
  background-color: transparent;
}

.close_btn_canvas {
  background-color: rgb(236, 236, 236);
  border: none !important;
  padding: 5px 10px !important;
  border-radius: 100px !important;
  height: 27px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_btn_canvas:hover {
  background-color: rgb(225, 225, 225) !important;
}

.btn_canvs {
  background-color: rgb(255, 255, 255);
  border: none !important;
  padding: 5px !important;
  border-radius: 100px !important;
  height: 27px !important;
  width: 27px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_canvs:hover {
  background-color: rgb(255, 255, 255) !important;
}

.btn_canvs svg {
  stroke: #585858 !important;
  height: 14px !important;
  width: 14px !important;
}

.close_btn_canvas svg {
  height: 14px !important;
  width: 14px !important;
  stroke: #585858 !important;
}

.trsh_btn svg {
  stroke: rgb(176, 11, 11) !important;
}

.view_docments_btn {
  background-color: black !important;
  color: #fff;
  font-size: 12px !important;
  padding: 5px 10px !important;
  font-family: SF-Pro-Display-Medium !important;
  display: flex;
  align-items: center;
}

.view_docments_btn:hover {
  background-color: black !important;
}

.view_docments_btn svg {
  height: 14px !important;
  width: 14px !important;
  stroke: #fff !important;
}

.heading_convss {
  font-size: 15px;
  color: #2f2f30;
  font-family: SF-Pro-Display-Semibold !important;
  margin: 0 !important;
}

.datetim_canv {
  font-size: 10px !important;
  color: grey !important;
  margin-right: 10px;
  font-family: SF-Pro-Display-Semibold !important;
}

.exten_canv {
  font-family: SF-Pro-Display-Semibold !important;
  font-size: 9px !important;
  color: #2c2c2c;
  background-color: rgb(237, 237, 237);
  padding: 4px 8px;
  border-radius: 4px;
}

.complet_tags {
  font-family: SF-Pro-Display-Semibold !important;
  font-size: 9px !important;
  color: #0faf35;
  background-color: rgb(221, 255, 231);
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.complet_tags svg {
  stroke: #0faf35;
  height: 12px;
  width: 12px;
}

.summerycarrd {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.summerycarrd h2 {
  font-size: 12px !important;
  font-family: SF-Pro-Display-Semibold !important;
  color: #5a5a5a;
  margin: 0 !important;
}

.summerycarrd p {
  font-family: SF-Pro-Display-Medium !important;
  color: #6b6b6b;
  margin: 4px 0 !important;
}

.table_det_canv tr th {
  font-family: SF-Pro-Display-Semibold !important;
  font-size: 12px !important;
  color: #3e3e3e !important;
  padding: 8px 0;
  background-color: transparent !important;
  border: none !important;
}

.table_det_canv tr td {
  font-family: SF-Pro-Display-Semibold !important;
  font-size: 12px !important;
  color: #8f8f8f;
  padding: 8px 0;
  background-color: #fff !important;
  border: none !important;
}

.table_det_canv tr td:nth-child(2) {
  color: #1f1f22 !important;
  background-color: #fff !important;
}

.dots_drop_mdl {
  border: none !important;
}

.dots_drop_mdl button::after {
  display: none;
}

.dots_drop_mdl button {
  background-color: #fff !important;
  height: 25px;
  width: 25px;
  border: 1px solid rgb(212, 212, 212) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 0 !important;
}

.dots_drop_mdl svg {
  height: 12px;
  width: 12px;
  stroke: grey;
}

.can_mod_uls li a {
  padding: 2px 10px !important;
  font-size: 11px !important;
}

.can_mod_uls li {
  margin: 0 !important;
}

.actions_ulss li button {
  font-size: 13px !important;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 0 !important;
}

.actions_ulss li {
  margin-bottom: 0px !important;
}

.actions_ulss li button svg {
  height: 14px;
  width: 14px;
  stroke: #000;
  margin-right: 8px !important;
}

.th_no_wrps th {
  white-space: nowrap !important;
}

.drp_btn_tds::after {
  display: none !important;
}

.drp_btn_tds {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px !important;
  border: 1px solid rgb(23, 23, 23);
  margin-left: auto !important;
}

.drp_btn_tds svg {
  margin: 0 !important;
  stroke: rgb(23, 23, 23);
}

.wid_40pxls {
  width: 40px !important;
}
.wid_60pxls {
  width: 60px !important;
}

.task_tabl tr th:nth-last-child(1) {
  border-radius: 0 7px 0 0 !important;
}

.doc_namess_th tr th:nth-last-child(1) {
  border-radius: 7px 7px 0 0 !important;
}

.min-wid-100pr {
  min-width: 100% !important;
}

.modle_close_btns {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.modle_close_btns svg {
  stroke: #aaaaaa !important;
}

.tsk_tbls_modl li button {
  margin: 0 !important;
  border: none !important;
  font-size: 13px !important;
  border-radius: 5px !important;
  color: grey !important;
  margin-right: 10px !important;
}

.tsk_tbls_modl li button:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #000 !important;
}

.tsk_tbls_modl {
  border: none !important;
}

.tsk_tbls_modl li button.active {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #000 !important;
}

.border-btm-none {
  border-bottom: none !important;
}

.border-top-none {
  border-top: none !important;
}

.btn_cls_mdl {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: gray !important;
  font-size: 13px !important;
  border: none !important;
  margin: 0 !important;
  padding: 7px 15px !important;
}

.tables_tab_conteents table thead tr th {
  white-space: nowrap !important;
}

.tables_tab_conteents {
  background-color: transparent !important;
}

#chart3 div div button {
  background-color: rgba(0, 0, 0, 1) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 13px !important;
  border: none !important;
  margin: 0 5px !important;
  padding: 7px 15px !important;
}

.font_heading_doc {
  font-size: 16px !important;
  margin-top: 8px !important;
}

.doc_nav_tbas_buttons {
  display: flex;
}

.doc_nav_tbas_buttons button {
  background-color: transparent !important;
  color: #70707d !important;
  margin-right: 10px !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  padding: 10px 25px !important;
  font-family: SF-Pro-Display-Medium !important;
  display: flex;
  align-items: center;
}

.doc_nav_tbas_buttons button.active {
  background-color: #edeef4 !important;
  color: #000 !important;
  font-family: SF-Pro-Display-Medium !important;
}

td,
th {
  background-color: #fff !important;
}

.sidenav a button svg {
  margin-right: 5px !important;
}

.doc_nav_tbas_buttons button svg {
  height: 16px !important;
  width: 16px !important;
}

.pos_togle_bttns {
  position: absolute;
  right: 15px;
  top: -54px;
}

.flex_grids_reports {
  display: flex;
  gap: 10px;
}

.edit-button {
  background-color: #161616 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 7px 30px !important;
  margin: 8px 2px !important;
}

.bg-trans {
  background-color: #fff !important;
}

/* The switch - the box around the slider */
.container_cheks_e {
  width: 51px !important;
  height: 31px !important;
  position: relative;
}

/* Hide default HTML checkbox */
.checkbox_chss_fee {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.switch_chss_fee {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #e9e9eb;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

/* The slider */
.slider_fee_tgl {
  width: 27px;
  height: 27px;
  position: absolute;
  left: calc(50% - 27px / 2 - 10px);
  top: calc(50% - 27px / 2);
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.checkbox_chss_fee:checked + .switch_chss_fee {
  background-color: #000;
}

.checkbox_chss_fee:checked + .switch_chss_fee .slider_fee_tgl {
  left: calc(50% - 27px / 2 + 10px);
  top: calc(50% - 27px / 2);
}

.wid_of_canvas2 {
  width: calc(100vw - 550px) !important;
  background-color: transparent !important;
  border: none !important;
}

.docx-wrapper {
  padding: 0 !important;
}

.docx-wrapper > section.docx {
  box-shadow: none !important;
}

.docx {
  width: auto !important;
  width: 100% !important;
  padding: 50px 50px 50px 30px !important;
}
.mdl-neww{
  max-width: 1400px !important;
}

.profile-settings .wrapper {
  padding: 0 !important;
}

.airisktables {
  border: none !important;
  margin: 0 !important;
}

.airisktables li button {
  background-color: transparent !important;
  font-size: 13px !important;
  border: none !important;
  color: #70707d !important;
  margin: 0;
  margin-right: 5px !important;
}

.airisktables li button:hover {
  background-color: #eeeff4 !important;
}

.airisktables li button.active {
  background-color: #eeeff4 !important;
  color: #000 !important;
}

.readline-btn {
  background-color: #eeeff4 !important;

  border: 1px solid #70707d !important;
}

.readline-btn svg {
  stroke: #70707d !important;
}

.nodatafounds {
  font-size: 13px !important;
}

.document-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  text-align: left;
  color: #333;
  font-size: 16px;
  position: relative;
}

.dropdown-toggle:after {
  content: '▼';
  position: absolute;
  right: 15px;
  font-size: 12px;
  color: #70707d;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  max-height: 150px;
  /* Adjust height if needed */
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 8px;
}

.document-option {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.document-option input[type='checkbox'] {
  width: 18px;
  height: 18px;
  accent-color: #6b63ff;
  /* Custom checkbox color */
  cursor: pointer;
}

.document-option label {
  font-size: 16px;
  color: #333;
}

.document-option:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

.document-option input[type='checkbox']:hover {
  border: 1px solid #6b63ff;
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.button {
  padding: 6px 12px;
  margin: 0 4px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  border-radius: 4px;
}

.columns-button {
  background-color: #f0f0f0;
  color: black;
}

.columns-button:hover {
  background-color: #b4bad9;
}

.reset-button:hover {
  background-color: #b4bad9;
}

.reset-button {
  background-color: transparent;
  color: #333;
}

.save-button {
  background-color: #4caf50;
  color: white;
}

.column-modal {
  position: absolute;
  top: 130px;
  right: 172px;
  width: 300px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
  border-radius: 8px;
}

.column-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #ff0000;
  background-color: #f8f8f8;
}

.modal-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.column-search {
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 12px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-size: 14px !important;
  margin-top: 8px !important;
}

.column-section {
  margin-bottom: 16px;
}

.column-item {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.drag-icon {
  margin-right: 8px;
  color: #888;
  cursor: grab;
}

.column-name {
  flex-grow: 1;
}

.eye-icon {
  cursor: pointer;
}

.extract_tbl_search input {
  border: 1px solid #cdcdce !important;
  border-radius: 100px;
  font-size: 13px !important;
  width: 250px;
  padding: 5px 13px;
  color: #2d2d2d !important;
  outline: none;
}

.tble_extrcts thead tr th {
  white-space: nowrap !important;
  min-width: 250px !important;
}

.tble_extrcts thead tr th:nth-child(1) {
  white-space: nowrap !important;
  min-width: 50px !important;
}

.tble_extrcts tr th:nth-child(7) {
  border-radius: 0 0 0 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}

.btn_ext_tbl {
  border: none !important;
  background-color: #eeeff4 !important;
  border-radius: 5px !important;
  border-radius: 5px;
  padding: 0 !important;
  margin-left: 4px !important;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btn_ext_tbl svg {
  color: #2a2d3a;
  height: 16px;
  width: 16px;
}

.btn_ext_tbl.active {
  background-color: #000 !important;
}

.btn_ext_tbl.active svg {
  color: #fff !important;
}

.btn_ext_tbl span {
  color: rgb(42, 45, 60);
  background-color: #eeeff4;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 11px;
  padding: 3px 10px;
  position: absolute;
  top: -28px;
  left: -12px;
  display: none;
}

.btn_ext_tbl:hover span {
  display: block;
}

.border_radi_eigh {
  border-radius: 8px !important;
  background-color: transparent !important;
}

.col_heading_h5 {
  font-size: 14px !important;
  color: #000 !important;
  font-family: SF-Pro-Display-Medium !important;
}

.col-close-button {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
}

.col-close-button svg {
  stroke: #989898 !important;
}

.column-item {
  font-size: 13px !important;
  background-color: #eeeff4 !important;
  border: none !important;
}

.summary-text {
  line-height: 1.5;
  color: #333;
}

.summary-controls {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.font-size-button {
  background-color: lightgray;
  color: white;
  border: none;
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
}

.font-size-button:hover {
  background-color: darkgray;
}

.summary-controls span {
  font-size: 10px;
  color: #555;
}

.zoom-button {
  padding: 5px 10px;
  background-color: lightgray;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.zoom-button:hover {
  background-color: darkgray;
}

.zoom-level {
  font-size: 14px;
  color: #333;
}

.main-content {
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.zoom-controls {
  border: none !important;
  padding: 5px 10px !important;
  border-radius: 100px !important;
  height: 27px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}
.bordertdnone td{
  border: none !important ;
}