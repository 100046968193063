.qa-admin-tools {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  color: #007bff;
  font-weight: 700;
}

.admin-section,
.add-admin-section,
.category-section,
.questions-section {
  margin-top: 30px;
}

.admin-section h3,
.add-admin-section h3,
.category-section h3,
.questions-section h3 {
  font-size: 22px;
  margin-bottom: 20px;
  color: #333;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.admin-table th,
.admin-table td {
  padding: 12px 15px;
  text-align: left;
}

.admin-table th {
  background-color: #007bff;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}

.admin-table tr {
  border-bottom: 1px solid #dddddd;
}

.admin-table tr:nth-child(even) {
  background-color: #f3f3f3;
}

.admin-table td {
  color: #555;
}

.status-active {
  color: #28a745;
  font-weight: bold;
}

.status-inactive {
  color: #dc3545;
  font-weight: bold;
}

.activate-button,
.deactivate-button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activate-button {
  background-color: #28a745;
  color: white;
}

.activate-button:hover {
  background-color: #218838;
}

.deactivate-button {
  background-color: #dc3545;
  color: white;
}

.deactivate-button:hover {
  background-color: #c82333;
}

.add-admin-section h3 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.add-admin-section form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-admin-section input,
.add-admin-section select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-admin-section button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-admin-section button:hover {
  background-color: #0056b3;
}

.category-section select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.question-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.question-editor {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-container {
  display: flex;
  gap: 10px;
}

.option-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.question-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
}

.question-editor button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.question-editor .delete-button {
  background-color: #dc3545;
}

.question-editor .revert-button {
  background-color: #ffc107;
}

.question-editor button:hover {
  background-color: #0056b3;
}

.question-editor .delete-button:hover {
  background-color: #c82333;
}

.question-editor .revert-button:hover {
  background-color: #e0a800;
}

.error {
  color: #dc3545;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}
.option-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.option-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.delete-option-button {
  width: 50px;
  position: absolute;
  right: 5px; /* Adjust as needed to position within padding */
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  top: 25%;
  color: #dc3545;
  height: 34px;
  cursor: pointer;
  opacity: 0.7; /* Makes the button semi-transparent */
  padding: 0;
}
.delete-option-button:hover {
  background-color: #007bff;
  color: white;
  opacity: 1; /* Fully opaque on hover */
}

.new-question-section input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
}

.edit-admin-section {
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px; /* Rounded corners */
  padding: 20px; /* Padding around the content */
  margin: 20px 0; /* Margin for spacing */
}

.edit-admin-section h3 {
  margin-bottom: 15px; /* Space below the heading */
}

.edit-admin-section form {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack items vertically */
}

.edit-admin-section input,
.edit-admin-section select {
  padding: 10px; /* Padding inside the input fields */
  margin-bottom: 15px; /* Space between inputs */
  border: 1px solid #ccc; /* Border for inputs */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Font size */
}

.edit-admin-section input:focus,
.edit-admin-section select:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.edit-admin-section button {
  padding: 10px 15px; /* Padding for buttons */
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Font size */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.edit-admin-section button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.edit-admin-section .cancel-button {
  background-color: #6c757d; /* Secondary color for cancel button */
}

.edit-admin-section .cancel-button:hover {
  background-color: #5a6268; /* Darker shade on hover for cancel button */
}
