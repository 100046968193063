.App {
  font-family: sans-serif;
  display: flex;
}

.content {
  margin-left: 235px;

  flex: 1;
}

.sidenav.user {
  height: 100%;
  width: 235px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fbfcfd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 2px solid rgba(112, 115, 147, 0.1);
  padding-top: 10px;
}

.sidenav.user .logo-class {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-bottom: 2px solid rgba(112, 115, 147, 0.1);
  margin-bottom: 20px;
}

.sidenav.user .logo-image.user {
  padding: 5px;
  width: 70%;
  padding-bottom: 16px !important;
}

.sidenav.user button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  margin: 5px 0;
  border: none;
  background-color: transparent;
  color: #363644;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: SF-Pro-Display-Medium !important;

}
.oprectionul{
  margin: 0 !important;
  padding: 0 !important;
}
.oprectionul li{
  list-style-type: none !important;
}
.oprectionul li a{
  font-family: SF-Pro-Display-Medium !important;
  list-style-type: none !important;
  font-size: 14px !important;
  padding: 8px 20px !important;
  color: #363644 !important;
  margin-bottom: 3px !important;
  cursor: pointer;
  margin-left: 2px !important;
  margin-top: 5px !important;
  display: block !important;
  width: 100% !important;

}
.oprectionul li a:hover{
  background-color: #f3f4f7 !important;
  border-radius: 8px !important;
}
.mleft-auto{
  margin-left: auto !important;
}
.opci-non{
  position: absolute;
  opacity: 0;
}
.upload-buttons2{
  background-color: #161616 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 7px 30px !important;
}
.text-workflow{
  height: 200px !important;
  border-radius: 8px !important;
  display: block;
  border: 1px solid rgba(0, 0, 0, .2);
  font-size: 14px !important;
  padding: 10px !important;
  margin-bottom: 7px;

}

.sidenav.user button:hover,
.sidenav.user button.active {
  background-color: #f3f4f7;
  border-radius: 10px;
  color: #000000 !important;
}

.sidenav.user button img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.sidenav.user #Task .badge {
  background-color: #e0e0e0;
  color: #5a5a5a;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: 10px;
}

.sidenav.user .logout-button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
  height: 40px;
  margin-bottom: 28px;
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 12px;
  cursor: pointer;
}

.submenu .logout-button {
  background-color: #f3f4f7 !important;
  margin-bottom: 0 !important;
}

.sidenav.user .logout-button img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.sidenav.user .logout-button:hover {
  background-color: #e7effb;
  border-radius: 10px;
}

.sidenav.user a {
  width: 100%;
  padding: 0 10px;

}

.sidenav.user .logout-button.user {
  width: 100%;
  height: 40px;

}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2a3e53;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;
  border-right: 2px solid rgba(112, 115, 147, 0.1);
}

.sidenav button {
  padding: 15px 25px;
  margin: 10px 0;
  width: 150px;
  border: none;
  border-radius: 5px;
  background-color: #fbfcfd;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.sidenav button:hover {
  background-color: #b7b7b7;
}

.sidenav .logout-button {
  margin-top: auto;
  margin-bottom: 50px;
}

.logo-class img {
  width: 200px;
}

.settings-menu {
  position: relative;
  padding: 0 20px;
  width: 100%;
}

.company-info {
  border:none !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 200px;
  padding: 8px 20px;
  background-color: #f3f4f7 !important;
}

.company-logo {
  width: 30px;
  height: 30px;
  border-radius: 100px !important;
  border: 1px solid rgba(0, 0, 0, .3);
  padding: 2px !important;
  margin-right: 10px;
  border-radius: 50%;
}

.company-name {
  flex: 1;
  color: #000;
  font-size: 12px;
  font-weight: bold;
}

.chevron-icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.logout-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  background-color: transparent;
  color: #000;
  font-size: 12px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #e7effb;
  border-radius: 10px;
}

.logout-button img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.sidenav.user .logout-button.user:hover {
  background-color: transparent;
}

.submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 75px;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  z-index: 10;
  background-color: transparent;
  padding: 0 20px !important;
}

.submenu button {
  padding: 8px 20px;
  font-size: 12px;
  text-align: left;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/*
#User-Password {
  left: 30px;
  margin: 0px;
  width: 150px;
  position: absolute;
}

#submenu-password {
  position: relative;
}

*/
.submenu button:hover {
  background-color: #e7effb;
  border-radius: 5px;
}

.submenu button img {
  margin-right: 8px;
}

.settings-menu button.active + .submenu {
  display: block;
}
.wsp-nowrap{
  white-space: nowrap !important;
}