.terms-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: start;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
}

.terms-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.logo {
  width: 180px;
  margin-bottom: 10px;
}

h1 {
  font-size: 32px;
}

.terms-content {
  font-size: 16px;
  color: #333;
}

h2 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
}

p,
li {
  font-size: 16px;
  margin-bottom: 10px;
}

a {
  color: #0073e6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ol {
  margin-left: 20px;
}
