.completed-analyses {
    padding: 20px;
  }
  
  .back-button {
    background-color: #3f51b5;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .back-button:hover {
    background-color: #303f9f;
  }
  
  .file-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .file-table th, .file-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .file-table th {
    background-color: #f2f2f2;
  }
  
  .file-table td {
    text-align: center;
  }
  
.completed-analyses {
  padding: 20px;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.file-table th, .file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.file-table th {
  background-color: #f2f2f2;
}

.file-table td {
  text-align: center;
}
