.workspace {
  padding: 20px;
}

.section-head {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.file-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.file-table tr:hover {
  background-color: #f1f1f1;
}

.file-actions {
  display: flex;
  gap: 5px;
}

.file-actions button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.btn-edit {
  background-color: #f0c674;
}

.btn-accept {
  background-color: #2e3b80;
  color: white;
}

.btn-decline {
  background-color: #f27573;
}

.btn-reassign {
  background-color: #67c584;
}

.btn-return {
  background-color: #f27573;
}

.pagination {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: end;
}

.pagination span {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination li {
  margin-bottom: 0 !important;
}
.pagination li a {
  font-size: 12px !important;
  background-color: transparent !important;
  color: grey !important;
  border: none !important;
  padding: 3px 9px !important;
  margin: 0 10px;
}
.pagination li:nth-child(1) {
  border: none !important;
  background-color: transparent !important;
  color: grey !important;
}
.pagination li:nth-last-child(1) {
  border: none !important;
  background-color: transparent !important;
  color: grey !important;
}
.pagination .page-item.active .page-link {
  border: 1px solid #000;
  color: #ffffff !important;
  background: #000 !important;
  border-radius: 3px !important;
}

.error {
  color: red;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  min-width: 500px;
  max-width: 500px;
  width: 100%;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.modal-content button {
  padding: 10px 20px;
  margin: 10px;
}
