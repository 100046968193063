.readline-container {

  width: 100%;
  min-height: 100vh;
}

.chat-window {
  border-right: 0 solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}
.files-list {
  padding: 20px;
  text-align: center;
  overflow: auto;
  width: 100%;
}

.select-member {
  padding: 9px 15px;
  line-height: 1.5;
  border-radius: 5px;
}

.chat-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-height: 52vh;
  min-height: 250px;
}

.message {
  margin-bottom: 5px;
  padding: 5px 10px;
  display: inline-block;
  background-color: #ececec;
  border-radius: 8px;
}

.chat-input {
  position: relative;
}

.chat-input input {
  flex: 1;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 15px;
}

.chat-input button {
  padding: 5px;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 5px;
  color: #1f1f1f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
}
.chat-input button svg {
  width: 18px;
  height: 18px;
  stroke: grey;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chat-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.chat-actions {
  display: flex;
  justify-content: flex-end;
}

.chat-audit-btn {
  width: 160px;
  margin-bottom: 10px;
  background-color: black;
}

.chat-audit-btn:hover {
  background-color: black;
}

.dropdown {
  min-width: 100px;
  border-radius: 2px;
  border-style: dotted;
}

.approvals-section {
  position: relative;
}

.approvals-section .popup-overlay-approval {
  position: absolute;
  top: 10%; /* Position it near the top of the approvals section */
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  width: 80%; /* Match section width */
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.popup-container {
  background: rgb(249, 250, 251);
  padding: 20px 0px;
  margin: 10px 0;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
