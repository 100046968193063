.document-viewer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.document-main {
  display: flex;
  margin-top: 20px;
}

.document-content {
  flex: 2;
  margin-right: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f9f9f9;
}

.sidebar-document {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.file-info {
  background-color: #f1f1f1;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
}

.question-container {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
}

.question-header,
.answer-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.answer-dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.question-input,
.remarks-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.notes {
  background-color: #f1f1f1;
  padding: 10px;
  border: 1px solid #ddd;
  width: 100%;
}

.notes .note {
  font-weight: bold;
}

.review-progress {
  margin-top: 20px;
  width: 100%;
}

.review-progress button {
  margin-bottom: 10px;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.progress {
  height: 10px;
  background-color: #4caf50;
  border-radius: 5px;
  width: 0;
}
.go-back {
  width: 160px;
  height: 50px;
  margin-left: 0px;
}
button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.question-filter {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-filter label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.question-filter select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  appearance: none;
  transition: border-color 0.3s ease;
}

.question-filter select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.doc-download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.doc-download img {
  width: 25px;
  height: 25px;
}
.workflow-buttons:hover {
  background: transparent;
} 