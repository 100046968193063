.simple-document-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.go-back {
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.document-container {
  display: flex;
  height: 100%;
}

.document-viewer {
  flex: 3;
  border-right: none;
  height: calc(100vh - 65px);
}

.notes-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff !important;
}

.notes-section h3 {
  margin-bottom: 10px;
}

.notes-section textarea {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.notes-section button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
