.document-editor-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.go-back2 {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.editor-container {
  display: flex;
  height: 100%;
  flex: 1;
}
.editor-container h3 {
  margin-bottom: 10px;
}

.editor-container textarea {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.document-view-pane {
  flex: 3;
  padding: 1rem;
  padding-top: 0px;
  overflow: auto;
  border-right: none;
  height: calc(100vh - 65px);
}

.document-view-pane .document-viewer {
  white-space: pre-wrap; /* Maintain line breaks */
  background-color: #fff !important;
}

.editor-sidebar {
  flex: 1;
  padding: 1rem;
  background-color: #fff !important;
}

.selected-text-input {
  width: 100%;
  height: 150px;
  margin-bottom: 1rem;
}

.replace-button {
  background-color: #000 !important;
  font-family: SF-Pro-Display-Medium !important;
  font-size: 13px !important;
  color: #ffffff !important;
  padding: 10px !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  width: 165px !important;
}
