.password-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
}

.password-settings h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.password-settings form {
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.password-settings label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333;
}

.password-field {
  position: relative;
}

.password-field input {
  display: flex;
}
.password-field img {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background: #ececec;
    padding: 2px;
    border-radius: 2px;
}

.password-field input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  outline: none;
}

.password-field .password-toggle {
  position: absolute;
  right: 3px;
  width: 30px;
  padding-left: 7px;
  border-left: 1px solid #ddd;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-container input {
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.cancel-button {
  background-color: whitesmoke;
  border-radius: 50px;
  color: #5a67d8;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
}

.cancel-button:hover {
  cursor: pointer;
  text-decoration: underline;
  background-color: whitesmoke;
}

.submit-button {
  background-color: #020202;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 !important;
}

.submit-button:disabled {
  border-radius: 50px;
  background-color: #e2e8f0;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  border-radius: 50px;
  background-color: #4c51bf;
}
