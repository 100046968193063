.documents-container {
  padding: 10px;
  background-color: transparent;
}

.documents-nav {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.documents-nav button {
  padding: 10px 20px;
  border: none;
  background-color: #f9f9f9;
  cursor: pointer;
  font-weight: bold;
  color: #555;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.documents-nav button.active {
  border-bottom: 2px solid #4a90e2;
  color: #4a90e2;
}

.documents-table-container {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.documents-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.documents-table th,
.documents-table td {
 
  padding: 8px;
  text-align: left;
}

.documents-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.documents-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.documents-table tr:hover {
  background-color: #f1f1f1;
}

.view-button {
  background-color: #4caf50;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  font-weight: bold;
}

/* General page styling */
.page-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.tab-content {
  margin-top: 20px;
}

.tab-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.tab-content .cards {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background-color: #ffffff;
}

/* Loader styling */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BeatLoader {
  margin-top: 20px;
}

/* Main Container */
.create-doc-container {
  max-width: 900px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Title */
.create-doc-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Toolbar */
.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.toolbar-btn {
  padding: 8px 12px;
  border: 1px solid #ccc;
  color: black;
  background-color: #f9f9f9;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toolbar-btn:hover {
  background-color: #f1f1f1;
}

.font-select,
.font-size-select {
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.color-btn {
  background-color: #ff6347;
  color: white;
  border: none;
}

.color-btn:hover {
  background-color: #e04c3a;
}

/* Text Editor */
.text-editor {
  min-height: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  outline: none;
  white-space: pre-wrap;
  overflow-y: auto;
}

.text-editor:empty::before {
  content: attr(placeholder);
  color: #aaa;
  font-style: italic;
}

/* Create Button */
.create-doc-btn {
  display: block;
  margin: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-doc-btn:hover {
  background-color: #0056b3;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.modal-content {
  margin: auto;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 20px;
}

.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.modal-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.modal-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
