.home {
  padding: 20px;
}

.section {
  margin-bottom: 40px;
}

.section-head {
  background-color: #3f51b5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

.section-button:hover {
  background-color: #303f9f;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.file-table th {
  background-color: #f2f2f2;
}

.file-table td {
  text-align: center;
}

/* .step-progress {
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
} */

.step-progress .progress {
  height: 100%;
  border-radius: 5px;
  background-color: #4caf50;
}

.step-progress {
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.step-progress.green {
  background-color: #4caf50;
}
