.checkbox-container {
  display: flex;
  align-items: center;
  gap: var(--font-sm);
  margin-bottom: 20px;
}

.auth-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
}

.logo {
  width: 180px;
  display: block;
  margin: 20px auto;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.auth-btn {
  width: 100%;
  padding: 12px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.auth-btn:hover {
  background-color: #222;
}

.signup-btn {
  width: 100%;
  padding: 0px;
  background-color: transparent;
  color: #000;
  border: none;
  font-size: 16px;
}

.signup-btn:hover {
  background-color: transparent;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

.side-by-side {
  display: flex;
  justify-content: space-between;
}

.half-width {
  width: 48%;
}

.signin-link {
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.signin-link:hover {
  text-decoration: underline;
}
