.tools-page {
  padding: 20px;
}

.tools-nav {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  color: #363644 !important;
  font-size: 13px !important;
  letter-spacing: 0.2px !important;
  display: flex !important;
  align-items: center !important;
  gap: 0 !important;
  margin: 0;
  font-family: SF-Pro-Display-Semibold;
}

.tools-nav button {
  padding: 0.5rem 1rem;
  color: #8c8c8c !important;
  border-radius: 5px solid black;
  cursor: pointer;
}

.tools-nav .active {
  background-color: #f3f4f7 !important;
  border-radius: 5px !important;
  padding: 7px 18px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-family: SF-Pro-Display-Semibold !important;
}

.tab-content {
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.file-upload-label {
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}

.convert-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.convert-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}
